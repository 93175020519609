import { Fragment } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

function NavButon(props) {
  const c = useHistory();
  return (
    <Fragment>
      <li className={props.current === props.name ? "current-menu-item" : ""}>
        <Link to={props.dir}>{props.name}</Link>
      </li>
    </Fragment>
  );
}

export default NavButon;
