import {useRef,useEffect} from 'react'

 const Loading=()=>{

    const cargando=useRef();

    useEffect(() => {
      const move=((cargando.current.getBoundingClientRect().top*-1))
      if (move<200) return
      window.scrollTo(0,450)
    },[])
  
    return <div className="d-flex justify-content-center p-3" ref={cargando} >
                <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
}

export default Loading;