import { Link, useParams } from "react-router-dom";
import { Fragment, useState } from "react";

const Article1 = () => {
  return (
    <main role="main" className="container">
      <div className="row">
        <div className="col-lg-12">
          <section className="page">
            <div className="page__breadcrumb1s">
              <span className="page__breadcrumb1s_here">Estás aquí:&nbsp;</span>
              <span className="page__breadcrumb1s_home">
                <Link to="index">Inicio</Link>&nbsp;/&nbsp;
              </span>
              <span>Atención ciudadana</span>
            </div>

            <h1 className="page__title">Atención ciudadana</h1>

            <div className="page__control">
              <script
                type="text/javascript"
                src="//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5b2a95135b7f0c9b"
              ></script>
              <div className="tools">
                <div className="addthis_inline_share_toolbox"></div>
              </div>
            </div>

            <article>
              <div className="row atencion-ciudadana">
                <div className="col-lg-3">
                  <Link
                    to="/atencion_ciudadana/conocetusderechos"
                    style={{ textDecoration: "none" }}
                    className="item"
                  >
                    <figure>
                      <img src="/assets/img/ac-01.svg" alt="img" />
                    </figure>
                    <h2>Conoce tus derechos</h2>
                    <p>
                      Entérate de tus derechos de acceder a la información
                      pública.
                    </p>
                  </Link>
                </div>
                <div className="col-lg-3">
                  <a
                    href="http://311.gob.do/"
                    target="_blank"
                    className="item"
                    style={{ textDecoration: "none" }}
                  >
                    <figure>
                      <img src="/assets/img/ac-02.svg" alt="" />
                    </figure>
                    <h2>
                      Accede al
                      <br /> portal 311
                    </h2>
                    <p>
                      Accede al portal de 311 sobre sugerencias, quejas,
                      reclamaciones y denuncias.
                    </p>
                  </a>
                </div>
                <div className="col-lg-3">
                  <Link
                    to="/atencion_ciudadana/convierteteenaliado"
                    className="item"
                    style={{ textDecoration: "none" }}
                  >
                    <figure>
                      <img src="/assets/img/ac-03.svg" alt="" />
                    </figure>
                    <h2>Provee para el Estado</h2>
                    <p>
                      Descubre todos los requisitos para convertirte en
                      proveedor del Estado.
                    </p>
                  </Link>
                </div>
                <div className="col-lg-3">
                  <a
                    href="tel:+*462"
                    className="item"
                    style={{ textDecoration: "none" }}
                  >
                    <figure>
                      <img src="/assets/img/ac-04.svg" alt="" />
                    </figure>
                    <h2>
                      Llama
                      <br /> al *462
                    </h2>
                    <p>
                      Recibe asistencia telefónica gratis sobre consultas y/o
                      trámites de las Instituciones.
                    </p>
                  </a>
                </div>
              </div>
            </article>
          </section>
        </div>
      </div>
    </main>
  );
};
const Article2 = () => {
  return (
    <main role="main" className="container">
      <div className="row">
        <div className="col-lg-12">
          <section className="page">
            <div className="page__breadcrumb1s">
              <span className="page__breadcrumb1s_here">Estás aquí:&nbsp;</span>
              <span className="page__breadcrumb1s_home">
                <Link to="index">Inicio</Link>&nbsp;/&nbsp;
              </span>
              <span className="page__breadcrumb1s_home">
                <Link to="/atencion_ciudadana">Atención ciudadana</Link>
                &nbsp;/&nbsp;
              </span>
              <span>Conoce tus derechos</span>
            </div>

            <h1 className="page__title">Atención ciudadana</h1>

            <div className="page__control">
              <script
                type="text/javascript"
                src="//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5b2a95135b7f0c9b"
              ></script>
              <div className="tools">
                <div className="addthis_inline_share_toolbox"></div>
              </div>
            </div>

            <article>
              <h2>Conoce tus derechos</h2>
              <div className="row">
                <div className="col-lg-8">
                  <p>
                    <b>Derechos al Libre Acceso a la Información Pública</b>
                    <br />
                    <b>Todo ciudadano(a) tiene derecho a:</b>
                  </p>

                  <p>
                    SOLICITAR información de cualquier órgano del Estado
                    Dominicano y de todas las sociedades anónimas, compañías
                    anónimas o compañías por acciones con participación estatal.
                  </p>

                  <p>
                    RECIBIR información completa, veraz, adecuada y oportuna de
                    cualquier órgano del Estado Dominicano y de todas las
                    sociedades anónimas, compañías anónimas o compañías por
                    acciones con participación estatal.
                  </p>

                  <p>
                    ACCESO gratuito a la información en tanto que no requiera
                    reproducción de la misma.
                  </p>

                  <p>
                    SOLICITAR, RECIBIR y DIFUNDIR informaciones pertenecientes a
                    la administración del Estado.
                  </p>

                  <p>
                    CONOCER los proyectos de reglamentos y otras disposiciones
                    de carácter general que rigen las relaciones entre los
                    particulares y la administración.
                  </p>

                  <p>
                    OBTENER información sobre los servicios que ofrece el
                    Estado, requisitos costos.
                  </p>

                  <p>
                    SABER la estructura, integrantes, normativas de
                    funcionamiento, proyectos, informes de gestión, y bases de
                    datos de la administración pública.
                  </p>

                  <p>
                    CONOCER sobre los presupuestos y cálculos de recursos y
                    gastos aprobados, su evolución y estado de ejecución.
                  </p>

                  <p>
                    TENER información sobre los programas y proyectos, sus
                    presupuestos, plazos, ejecución y supervisión.
                  </p>

                  <p>
                    ESTAR AL TANTO sobre las licitaciones, concursos, compras,
                    gastos y resultados.
                  </p>

                  <p>ACCEDER a la nómina del Estado y sus remuneraciones.</p>

                  <p>
                    CONOCER la declaración jurada patrimonial de los
                    funcionarios del Estado cuando su presentación corresponda
                    por ley.
                  </p>

                  <p>
                    RECIBIR información sobre el listado de beneficiarios de
                    programas asistenciales, subsidios, becas, jubilaciones,
                    pensiones y retiros.
                  </p>

                  <p>
                    SABER sobre el estado de cuentas de la deuda pública, sus
                    vencimientos y pagos.
                  </p>

                  <p>
                    BUSCAR las leyes, decretos, resoluciones, disposiciones,
                    marcos regulatorios y cualquier tipo de normativa.
                  </p>

                  <p>
                    SOLICITAR Y RECIBIR oportunamente cualquier tipo de
                    documentación financiera relativa al presupuesto público.
                  </p>

                  <p>
                    En cumplimiento a la Ley 200-04 y el Decreto 130-05, el
                    acceso a la información se hará efectivo siempre y cuando no
                    afecte la seguridad nacional, el orden público, la salud o
                    la moral pública o el derecho a la privacidad e intimidad de
                    un tercero o el derecho a la reputación de los demás.
                  </p>
                </div>
              </div>
            </article>
          </section>
        </div>
      </div>
    </main>
  );
};
const Article3 = () => {
  return (
    <main role="main" className="container">
      <div className="row">
        <div className="col-lg-12">
          <section className="page">
            <div className="page__breadcrumb1s">
              <span className="page__breadcrumb1s_here">Estás aquí:&nbsp;</span>
              <span className="page__breadcrumb1s_home">
                <Link to="index">Inicio</Link>&nbsp;/&nbsp;
              </span>
              <span className="page__breadcrumb1s_home">
                <Link to="/atencion_ciudadana">Atención ciudadana</Link>
                &nbsp;/&nbsp;
              </span>
              <span>Conviértete en un aliado</span>
            </div>

            <h1 className="page__title">Atención ciudadana</h1>

            <div className="page__control">
              <script
                type="text/javascript"
                src="//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5b2a95135b7f0c9b"
              ></script>
              <div className="tools">
                <div className="addthis_inline_share_toolbox"></div>
              </div>
            </div>

            <article>
              <h2>Conviértete en un aliado</h2>
              <div className="row">
                <div className="col-lg-8">
                  <b>
                    Los proveedores interesados en ofrecer bienes y servicios al
                    gobierno dominicano deben cumplir con los siguientes
                    requisitos:
                  </b>
                  <p>
                    1. Contar con el Registro de Proveedores del Estado (RPE),
                    según lo establecido en el portal de la Dirección General de
                    Contrataciones Públicas (DGCP).
                  </p>

                  <p>
                    2. Estar al día en el pago de sus obligaciones fiscales.
                  </p>

                  <p>
                    3. Depositar en el Departamento de Compras* un Portafolio de
                    Negocios que incluya:
                  </p>

                  <p>
                    Descripción de la empresa
                    <br />
                    Bienes y servicios ofrecidos
                    <br />
                    Servicios de valor añadido
                    <br />
                    Experiencia en el mercado
                    <br />
                    Referencias comerciales
                    <br />
                    Términos de pago
                    <br />
                    Términos de entrega
                  </p>

                  <p>
                    4. Participar de los procesos de las compras y
                    contrataciones publicados a través de este portal y la
                    Dirección General de Contrataciones Públicas y en el portal
                    de nuestra institución en la sección de transparencia.
                  </p>

                  <hr></hr>
                  <p>
                    Accede al portal de DGCP para conocer más detalles e iniciar
                    el proceso de solicitud.
                  </p>
                  <a
                    href="https://www.dgcp.gob.do/"
                    target="_ blank"
                    className="btn btn-inverted"
                  >
                    Ir al portal DGCP
                  </a>
                </div>
              </div>
            </article>
          </section>
        </div>
      </div>
    </main>
  );
};

export default function AtencionCiudadana() {
  const [listnomina, setlistnomina] = useState();

  const param = useParams();

  const params = useParams();

  if (param.article == "conocetusderechos") {
    return (
      <Fragment>
        <div
          className="page-cover"
          style={{ backgroundImage: "url('/assets/img/pages/atencion.jpg')" }}
        ></div>
        <Article2 />
      </Fragment>
    );
  } else if (param.article == "convierteteenaliado") {
    return (
      <Fragment>
        <div
          className="page-cover"
          style={{ backgroundImage: "url('/assets/img/pages/atencion.jpg')" }}
        ></div>
        <Article3 />
      </Fragment>
    );
  }

  return (
    <Fragment>
      <div
        className="page-cover"
        style={{ backgroundImage: "url('/assets/img/pages/atencion.jpg')" }}
      ></div>
      <Article1 />
    </Fragment>
  );
}
