import React from "react";
import ReactTooltip from "react-tooltip";

export default function Tag({ active, onClick, value }) {
  return (
    <div className="mt-2 mr-2 mb-2" style={{ cursor: "pointer" }}>
      <ReactTooltip id={value} place="top" type="dark" effect="solid">
        Filtrar por {value}
      </ReactTooltip>
      <span
        data-tip={`React-tooltip`}
        data-for={value}
        className={active ? "badge bg-light " : "badge bg-info text-white "}
        onClick={() => onClick(value)}
      >
        {value}
      </span>
    </div>
  );
}
