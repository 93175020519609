import Pagination from "@material-ui/lab/Pagination";

export default function PaginationControlled({eventChange,page,count}) {
  return (
      <Pagination
        count={count}
        page={parseInt(page,10)}
        color="primary"
        onChange={eventChange}
        showFirstButton 
        showLastButton 
        variant="outlined" 
        shape="rounded"
      />
  );
}