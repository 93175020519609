import { Link } from "react-router-dom";
import ArchiveCard from "../accessories/archivos-card";
import FolderIcon from "../../../images/icons8-file-folder-48.png";

export default function InstitutionFolders({ folders = [] }) {
  return (
    <div className="m-2">
      {folders.map((folder) => (
        <div
          key={folder.id}
          className="mr-5 my-4 shadow-sm p-3 mb-5 bg-white border-2"
        >
          <p
            role="button"
            className="row justify-content-between"
            data-toggle="collapse"
            data-target={`#multiCollapseExample${folder.id}`}
            aria-expanded="false"
            aria-controls={`multiCollapseExample${folder.id}`}
          >
            <div className="row mx-4">
              <img width="90" height="" src={FolderIcon} alt={folder.nombre} />
              <div className="align-self-center ml-2">
                <p className="h5 mb-0"> {folder.nombre}</p>

                <span className="row ml-1">
                  <p className="mr-2 font-weight-bold">
                    {folder.files.length || 0}
                  </p>
                  <p> archivos</p>
                </span>
              </div>
            </div>
            {/* <div className=""> */}
            <p
              className="btn-descargar align-self-center mr-4"
              style={{ cursor: "pointer" }}
            >
              <span className="hidden-xs">Abrir</span>
            </p>
            {/* </div> */}
          </p>
          {folder.files.map((file) => (
            <div className="col my-2">
              <div
                className="collapse multi-collapse"
                id={`multiCollapseExample${folder.id}`}
              >
                <ArchiveCard item={file} />
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}
