import { Link } from 'react-router-dom'
const PageIns = (props) => {

    const { path, title } = props;

    return (
        <div className="col-lg-6 col-12 page__ins">
            <Link to={path}>{title}</Link>
        </div>
    )
}

export default PageIns;