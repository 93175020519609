import { Link } from "react-router-dom";
import MenuItem from "./MenuItem";

function Navbar({ active }) {

    return (
        <nav className="la-menu">
            <div className="container" style={{fontWeight:700}}>
                <ul>
                    <MenuItem
                        current={active}
                        dir="/"
                        name="Inicio"
                    />

                    <MenuItem
                        current={active}
                        dir="/instituciones"
                        name="Instituciones"
                    />

                    <li><Link to="#">Consultas <i className="fa fa-angle-down ml-1"></i></Link>

                        <ul className="sub-menu">
                            <li><Link to="/consultas/nomina">Nómina</Link></li>
                            <li><Link to="/consultas/contratos">Certificaciones de contratos</Link></li>
                            <li><Link to="/compras">Compras y contrataciones</Link></li>
                        </ul>
                    </li>

                    <MenuItem
                        current={active}
                        dir="/atencion_ciudadana"
                        name="Atención ciudadana"
                    />

                    <MenuItem
                        current={active}
                        dir="/solicitud_de_informacion"
                        name="Solicitud de información"
                    />

                    <MenuItem
                        current={active}
                        dir="/contacto"
                        name="Contacto"
                    />
                </ul>
            </div>
        </nav>
    )
}
export default Navbar;