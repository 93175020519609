import url from "../../../url";
import exportNominaToExcel from "./create-excel-nomina";
import createExcel1 from "./create-excel";

export const exportTableNominaToExcel = exportNominaToExcel;
export const createExcel = createExcel1;

export const excelButtonStyle = {
  border: "none",
  background: "#2d572c",
  color: "#fff",
  fontSize: 13,
  padding: 8,
  borderRadius: 5,
  fontWeight: 600,
};

export const fetchRequest = async (queryParams) => {
  try {
    const res = await fetch(`${url}${queryParams}`, {
      method: "get",
      headers: new Headers({}),
    });
    return await res.json();
  } catch (error) {
    return error.response;
  }
};

export const buildQuery = (
  path,
  { periodSelected, periodDefault, institution = {} }
) => {
  path = path ? path + "?" : path;
  return (
    `${path || "nominas?paginar=30&"}periodo=${
      periodSelected || periodDefault
    }` +
    (institution?.organismo_id ? `&organismo=${institution.organismo_id}` : "")
  );
};

export const buildAndValidateParams = (searchParams) => {
  let isEmptyValue = true;
  const concatKeyValue = (value) => {
    return `&${value[0]}=${value[1]}`;
  };

  const params = Object.entries(searchParams).reduce((acc, curr) => {
    if (curr[1]) {
      isEmptyValue = false;
      return acc ? acc + concatKeyValue(curr) : concatKeyValue(curr);
    }
    return curr;
  }, "");

  return { params, isEmptyValue };
};

export const buildQueryParams = (buildQuery, buildParams) => {
  return buildQuery + buildParams;
};
