import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
//Importing Pages For Render
import NotFount from "./pages/404";
import ConfigContext from "./Context/ConfigContex";
import CustonLayout from "./components/Layout";
// Route For Public path
//PrivateRoute for private path

import "bootstrap";
require("bootstrap/dist/css/bootstrap.css");

function App() {
  return (
    <ConfigContext>
      <Router>
        <Switch>
          {/* <Route exact path="/">
            <Home />
          </Route> */}
          <Route path="/">
            <CustonLayout />
          </Route>
          {/* <Route exact path="/inicio">
            <Home />
          </Route>
          <Route exact path="/index">
            <Home />
          </Route>
          <Route exact path="/instituciones">
            <Ministerios />
          </Route>
          <Route exact path="/consultas/:search?/:pag?">
            <Consulta />
          </Route>
          <Route exact path="/atencion_ciudadana/:article?">
            <AtencionCiudadana />
          </Route>
          <Route exact path="/solicitud_de_informacion">
            <Inforequest />
          </Route>
          <Route
            exact
            path="/instituciones/oficinas/:intitucion/Nomina/:organismo/:pag?"
          >
            <Nomina />
          </Route>
          <Route exact path="/instituciones/:ints">
            <Ministerios />
          </Route>
          <Route exact path="/instituciones/Oficinas/Nomina/:userid">
            <UserProfil />
          </Route>
          <Route exact path="/inicio/Oficinas">
            <InstitucionesOficinas />
          </Route>
          <Route path="/instituciones/Oficinas/:oficina">
            <Oficina />
          </Route>
          <Route exact path="/instituciones/Ministerios">
            <Ministerios />
          </Route>

          <Route exact path="/inicio/transparenciaenlaestrategia">
            <LeyGeneral />
          </Route>

          <Route exact path="/inicio/marcolegaldetransparencia">
            <Transparencia />
          </Route>

          <Route exact path="/Compras">
            <Compras />
          </Route>

          <Route exact path="/Contacto">
            <Contacto />
          </Route> */}

          <Route>
            <NotFount />
          </Route>
        </Switch>
      </Router>
    </ConfigContext>
  );
}

export default App;
