import { Link, useParams } from "react-router-dom";
import Modal from "react-modal";
import { Fragment, useState } from "react";

const customStyles = {
  content: {
    top: "35%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
  },
  overlay: { zIndex: 1000 },
};

Modal.setAppElement("div");

const Solicitud = () => {
  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const MyModal = () => {
    return (
      <div style={{ zIndex: 110 }}>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div className="body d-flex flex-column">
            <svg
              width="65"
              height="65"
              viewBox="0 0 65 65"
              className="align-self-center"
            >
              <path
                id="check-mark"
                d="M46.867,22.188a2.539,2.539,0,0,1,0,3.591L29.833,42.812a2.54,2.54,0,0,1-3.591,0L18.133,34.7a2.539,2.539,0,0,1,3.59-3.59l6.313,6.313L43.276,22.188A2.54,2.54,0,0,1,46.867,22.188ZM65,32.5A32.5,32.5,0,1,1,32.5,0,32.482,32.482,0,0,1,65,32.5Zm-5.078,0A27.422,27.422,0,1,0,32.5,59.922,27.407,27.407,0,0,0,59.922,32.5Zm0,0"
                fill="#003876"
              />
            </svg>
            <p
              className="align-self-center mt-3"
              style={{ textAlign: "center" }}
            >
              Desea continuar como persona fisica o Persona Juridica?{" "}
            </p>

            <div className="align-self-center mt-3">
              <a
                href="https://www.saip.gob.do/apps/sip/?step=new-user&who=fisica"
                target="_blank"
                className="btn "
                style={{ width: 300 + "px" }}
              >
                Persona Física
              </a>
              <a
                href="https://www.saip.gob.do/apps/sip/?step=new-user&who=juridica"
                target="_blank"
                className="btn "
                style={{ width: 300 + "px" }}
              >
                Persona Juridica
              </a>
            </div>
          </div>
        </Modal>
      </div>
    );
  };

  return (
    <main role="main" className="container">
      <div className="row">
        <div className="col-lg-12">
          <MyModal />
          <section className="page">
            <div className="page__breadcrumb1s">
              <span className="page__breadcrumb1s_here">Estás aquí:&nbsp;</span>
              <span className="page__breadcrumb1s_home">
                <Link to="index">Inicio</Link>&nbsp;/&nbsp;
              </span>
              <span>Solicitud de información</span>
            </div>

            <h1 className="page__title">Solicitud de información</h1>

            <div className="page__control">
              <script
                type="text/javascript"
                src="//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5b2a95135b7f0c9b"
              ></script>
              <div className="tools">
                <div className="addthis_inline_share_toolbox"></div>
              </div>
            </div>

            <article>
              <div className="infos">
                <h2>Recomendaciones antes de continuar:</h2>
                <div className="body">
                  <p>
                    Verifique si la información que solicita está disponible en
                    la sección de la institución a la que le hace la solicitud.
                    Confirme que la información que solicita está el ámbito de
                    la Ley 200-04.
                  </p>
                </div>
              </div>

              <div className="row pasos">
                <div className="col-lg-4">
                  <div className="item">
                    <figure>
                      <img src="/assets/img/s01.svg" alt="" />
                    </figure>
                    <p>
                      Si ya posee un registro ingrese al Portal Único de
                      Solicitud de Acceso a la Información Pública (SAIP).
                    </p>
                    <button onClick={openModal} className="btn">
                      Registrarse
                    </button>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="item">
                    <figure>
                      <img src="/assets/img/s02.svg" alt="" />
                    </figure>
                    <p>
                      Verifique si la información que solicita está disponible
                      en la sección de la institución a la que le hace la
                      solicitud. Confirme que la información que solicita está
                      el ámbito de la Ley 200-04.
                    </p>
                    <a
                      href="https://saip.gob.do/apps/login.php"
                      target="_blank"
                      className="btn"
                    >
                      Ingresar aquí
                    </a>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="item">
                    <figure>
                      <img src="/assets/img/s03.svg" alt="" />
                    </figure>
                    <p>
                      Continuar sin registrarse y solo rellenar el formulario
                      directamente (única solicitud){" "}
                    </p>
                    <a
                      href="https://www.saip.gob.do/apps/sip/?step=two"
                      target="_blank"
                      className="btn"
                    >
                      Continuar
                    </a>
                  </div>
                </div>
              </div>
            </article>
          </section>
        </div>
      </div>
    </main>
  );
};

const Form1 = () => {
  return (
    <main role="main" className="container">
      <div className="row">
        <div className="col-lg-12">
          <section className="page">
            <div className="page__breadcrumb1s">
              <span className="page__breadcrumb1s_here">Estás aquí:&nbsp;</span>
              <span className="page__breadcrumb1s_home">
                <Link href="index">Inicio</Link>&nbsp;/&nbsp;
              </span>
              <span>Formulario para solicitud de información pública</span>
            </div>

            <h1 className="page__title">
              Formulario para solicitud de información pública
            </h1>

            <div className="page__control">
              <script
                type="text/javascript"
                src="//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5b2a95135b7f0c9b"
              ></script>
              <div className="tools">
                <div className="addthis_inline_share_toolbox"></div>
              </div>
            </div>

            <article>
              <div className="infos">
                <h2>Indicaciones:</h2>
                <div className="body">
                  Complete los datos que se le solicitan a continuación. Luego
                  de terminar el registro, se le enviará un correo electrónico
                  de confirmación con los datos y podrá acceder al sistema,
                  realizar las solicitudes y dar seguimiento.
                </div>
              </div>

              <div className="more">
                <h3>Información de usuario para el Solicitante</h3>
                <div className="content">
                  <form>
                    <div className="row">
                      <div className="col">
                        <input
                          type="text"
                          required
                          placeholder="Nombre del solicitante"
                        />
                      </div>
                      <div className="col">
                        <input
                          type="text"
                          placeholder="Cédula de Identidad, Pasaporte o otro"
                        />
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col">
                        <select name="" id="">
                          <option value="" disabled selected>
                            Rango de edad
                          </option>
                          <option value="">1-0</option>
                        </select>
                      </div>
                      <div className="col">
                        <select name="" id="">
                          <option value="" disabled selected>
                            Género
                          </option>
                          <option value="">M</option>
                          <option value="">F</option>
                        </select>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col">
                        <select name="" id="">
                          <option value="" disabled selected>
                            Nivel Académico
                          </option>
                          <option value="">M</option>
                          <option value="">F</option>
                        </select>
                      </div>
                      <div className="col">
                        <select name="" id="">
                          <option value="" disabled selected>
                            Profesión / Oficio
                          </option>
                          <option value="">M</option>
                          <option value="">F</option>
                        </select>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col">
                        <input type="text" placeholder="Teléfono" />
                      </div>
                      <div className="col">
                        <input type="text" placeholder="Correo electrónico" />
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col">
                        <select name="" id="">
                          <option value="" disabled selected>
                            Provincia / municipio
                          </option>
                          <option value="">M</option>
                          <option value="">F</option>
                        </select>
                      </div>
                      <div className="col">
                        <input type="text" placeholder="Dirección" />
                      </div>
                    </div>

                    <div className="infos red">
                      <h2>Datos de acceso al sistema</h2>
                      <div className="body">
                        Recuerda que puedes registrarte en el Portal Único de
                        Solicitud de Acceso a la Información Pública (SAIP) para
                        un mejor control y seguimiento y así consolidar todas
                        sus solicitudes futuras.
                      </div>
                    </div>

                    <a href="#" className="btn">
                      Continuar
                    </a>
                  </form>
                </div>
              </div>
            </article>
          </section>
        </div>
      </div>
    </main>
  );
};

const Form2 = () => {
  return (
    <main role="main" className="container">
      <div className="row">
        <div className="col-lg-12">
          <section className="page">
            <div className="page__breadcrumb1s">
              <span className="page__breadcrumb1s_here">Estás aquí:&nbsp;</span>
              <span className="page__breadcrumb1s_home">
                <a href="index.html">Inicio</a>&nbsp;/&nbsp;
              </span>
              <span>Formulario para solicitud de información pública</span>
            </div>

            <h1 className="page__title">
              Formulario para solicitud de información pública
            </h1>

            <div className="page__control">
              <script
                type="text/javascript"
                src="//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5b2a95135b7f0c9b"
              ></script>
              <div className="tools">
                <div className="addthis_inline_share_toolbox"></div>
              </div>
            </div>

            <article>
              <div className="infos">
                <h2>Indicaciones:</h2>
                <div className="body">
                  Complete los datos que se le solicitan a continuación. Luego
                  de terminar el registro, se le enviará un correo electrónico
                  de confirmación con los datos y podrá acceder al sistema,
                  realizar las solicitudes y dar seguimiento.
                </div>
              </div>

              <div className="more">
                <h3>Datos persona Jurídica (empresas, organizaciones)</h3>
                <div className="content">
                  <form>
                    <div className="row">
                      <div className="col">
                        <input
                          type="text"
                          required
                          placeholder="Nombre de la empresa u organización:"
                        />
                      </div>
                      <div className="col">
                        <input
                          type="text"
                          placeholder="Registro Nacional de Contribuyentes (RNC)"
                        />
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col">
                        <input type="tel" placeholder="Teléfono" />
                      </div>
                      <div className="col">
                        <input type="email" placeholder="Correo electrónico" />
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col">
                        <select name="" id="">
                          <option value="" disabled selected>
                            Provincia / municipio
                          </option>
                          <option value="">M</option>
                          <option value="">F</option>
                        </select>
                      </div>
                      <div className="col">
                        <input type="text" placeholder="Dirección" />
                      </div>
                    </div>

                    <div className="infos red">
                      <h2>Datos de acceso al sistema</h2>
                      <div className="body">
                        Recuerda que puedes registrarte en el Portal Único de
                        Solicitud de Acceso a la Información Pública (SAIP) para
                        un mejor control y seguimiento y así consolidar todas
                        sus solicitudes futuras.
                      </div>
                    </div>

                    <a href="#" className="btn">
                      Continuar
                    </a>
                  </form>
                </div>
              </div>
            </article>
          </section>
        </div>
      </div>
    </main>
  );
};

const Form3 = () => {
  return (
    <main role="main" className="container">
      <div className="row">
        <div className="col-lg-12">
          <section className="page">
            <div className="page__breadcrumb1s">
              <span className="page__breadcrumb1s_here">Estás aquí:&nbsp;</span>
              <span className="page__breadcrumb1s_home">
                <a href="index.html">Inicio</a>&nbsp;/&nbsp;
              </span>
              <span>Formulario para solicitud de información pública</span>
            </div>

            <h1 className="page__title">
              Formulario para solicitud de información pública
            </h1>

            <div className="page__control">
              <script
                type="text/javascript"
                src="//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5b2a95135b7f0c9b"
              ></script>
              <div className="tools">
                <div className="addthis_inline_share_toolbox"></div>
              </div>
            </div>

            <article>
              <div className="infos">
                <h2>Indicaciones:</h2>
                <div className="body">
                  Estas son las Instituciones disponibles para realizar una
                  Solicitud de Información Pública (SIP). <br />
                  Por favor, indique detalladamente que le motiva a realizar
                  esta solicitud.
                </div>
              </div>

              <div className="more">
                <h3>inFORMACIÓN SOLICITADA</h3>
                <div className="content">
                  <form>
                    <div className="row">
                      <div className="col">
                        <select name="" id="">
                          <option value="" disabled selected>
                            Tipo de institución
                          </option>
                          <option value="">M</option>
                          <option value="">F</option>
                        </select>
                      </div>
                      <div className="col">
                        <input
                          type="text"
                          placeholder="Instituciones disponibles"
                        />
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col">
                        <select name="" id="">
                          <option value="" disabled selected>
                            Tipo de Información que solicita
                          </option>
                          <option value="">M</option>
                          <option value="">F</option>
                        </select>
                      </div>
                      <div className="col">
                        <select name="" id="">
                          <option value="" disabled selected>
                            Medio de recepción:
                          </option>
                          <option value="">M</option>
                          <option value="">F</option>
                        </select>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col">
                        <textarea placeholder="Motivación de la solicitud de información:"></textarea>
                      </div>
                      <div className="col">
                        <textarea placeholder="Motivación de la solicitud de información:"></textarea>
                      </div>
                    </div>

                    <div className="form-group">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="a"
                          required
                        />
                        <label className="form-check-label" for="a">
                          <b>
                            Clic aquí para continuar con la Solicitud de
                            Información Pública, acepta que ha leído y
                            comprendido las Políticas y Condiciones Legales de
                            uso.
                          </b>
                        </label>
                      </div>
                    </div>

                    <a href="#" className="btn btn-inverted">
                      Volver atrás
                    </a>
                    <a href="" className="btn ml-3">
                      Continuar
                    </a>
                  </form>
                </div>
              </div>
            </article>
          </section>
        </div>
      </div>
    </main>
  );
};

const FormDone = () => {
  return (
    <main role="main" className="container">
      <div className="row">
        <div className="col-lg-12">
          <section className="page">
            <div className="page__breadcrumb1s">
              <span className="page__breadcrumb1s_here">Estás aquí:&nbsp;</span>
              <span className="page__breadcrumb1s_home">
                <a href="index.html">Inicio</a>&nbsp;/&nbsp;
              </span>
              <span>Formulario para solicitud de información pública</span>
            </div>

            <h1 className="page__title">
              Formulario para solicitud de información pública
            </h1>

            <div className="page__control">
              <script
                type="text/javascript"
                src="//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5b2a95135b7f0c9b"
              ></script>
              <div className="tools">
                <div className="addthis_inline_share_toolbox"></div>
              </div>
            </div>

            <article>
              <div className="row justify-content-center">
                <div className="col-lg-5">
                  <div className="infos">
                    <div className="body d-flex flex-column">
                      <svg
                        width="65"
                        height="65"
                        viewBox="0 0 65 65"
                        className="align-self-center"
                      >
                        <path
                          id="check-mark"
                          d="M46.867,22.188a2.539,2.539,0,0,1,0,3.591L29.833,42.812a2.54,2.54,0,0,1-3.591,0L18.133,34.7a2.539,2.539,0,0,1,3.59-3.59l6.313,6.313L43.276,22.188A2.54,2.54,0,0,1,46.867,22.188ZM65,32.5A32.5,32.5,0,1,1,32.5,0,32.482,32.482,0,0,1,65,32.5Zm-5.078,0A27.422,27.422,0,1,0,32.5,59.922,27.407,27.407,0,0,0,59.922,32.5Zm0,0"
                          fill="#003876"
                        />
                      </svg>
                      <p
                        className="align-self-center mt-3"
                        style="text-align: center;"
                      >
                        Registro de la solicitud de información completado de
                        forma satisfactoria. Recibirá la información solicitada
                        por la vía elegida.
                      </p>

                      <div className="align-self-center mt-3">
                        <a href="#" className="btn btn-inverted">
                          Nueva solicitud
                        </a>
                        <a href="#" className="btn">
                          Ok
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </section>
        </div>
      </div>
    </main>
  );
};

export default function InfoRequest() {

  return (
    <Fragment>
      <div
        className="page-cover"
        style={{ backgroundImage: "url('./assets/img/pages/solicitud.jpg')" }}
      ></div>
      <Solicitud />
    </Fragment>
  );
}
