import { Link } from "react-router-dom";
// import Logotica from "..pages/Logotica.png";
import Logotica from "../pages/Logotica.png";
import DGI from "../images/LogoticaTrasparencia.png";
//----------------------------
export default function Footer() {
  return (
    <footer className="footer">
      <div className="container">
        <div className="d-lg-block d-none">
          <a href="#top" className="toTop">
            Subir al Inicio<i className="fas fa-angle-up"></i>
          </a>
        </div>

        <div className="row justify-content-center m-f">
          <div className="col-lg-6">
            <div className="logo-footer d-none d-lg-block">
              <div className="logo-footer">
                <Link to="/index">
                  <img src="/assets/img/Texto_Blanco.svg" alt="Presidencia" />
                </Link>
              </div>
            </div>

            <div className="footer-info">
              <address style={{ fontSize: 16, fontWeight: 700 }}>
                Dirección General de Ética e Integridad Gubernamental{" "}
                <b>(DIGEIG)</b>
              </address>
              <address>
                Ave. Mexico # 419, Esq. Leopoldo Navarro, Edificio de Oficinas
                Gubernamentales Juan Pablo Duarte, Piso #12, Gazcue, Distrito
                Nacional, R.D.
              </address>

              <div className="contact-info">
                <span>
                  <a href="tel:+809-685-7135">Tel.: 809-685-7135</a>
                </span>
              </div>

              {/*<div className="menu-footer">
                                <ul>
                                    <li><a href="#">Términos de uso</a></li>
                                    <li><a href="#">Políticas de Privacidad</a></li>
                                    <li><a href="#">Preguntas frecuentes</a></li>
                                </ul>
                            </div>*/}

              <div className="copyrigt">
                ©2021 Todos los derechos reservados
              </div>
              <a href="https://optic.gob.do/" target="_blank" rel="noreferrer">
                <div className="copyrigt mt-4">
                  <img
                    width="85"
                    height="85"
                    src={DGI}
                    className="img-fluid"
                    alt="Creato por OPTIC"
                  />
                </div>
                <div className="copyrig mt-4">
                  <img
                    width="150"
                    height="150"
                    className="img-fluid"
                    src="/assets/img/Logo_OGTIC-vertical_color_blanco1.png"
                    alt="Creato por OPTIC"
                  />
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
