import React, { useEffect } from "react";
import { Fragment } from "react";

export default function Compras() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fragment>
      <div
        className="page-cover"
        style={{ backgroundImage: "url('/assets/img/compras.jpg')" }}
      ></div>
      <main role="main" className="container">
        <div className="row">
          <div className="col-lg-12">
            <section className="page compras">
              <div className="page__breadcrumb1s">
                <span className="page__breadcrumb1s_here">
                  Estás aquí:&nbsp;
                </span>
                <span className="page__breadcrumb1s_home">
                  <a href="index.html">Inicio</a>&nbsp;/&nbsp;
                </span>
                <span>Compras</span>
              </div>

              <h1 className="page__title">Consultas Compras Dominicana</h1>
              <div className="page__control">
                <script
                  type="text/javascript"
                  src="//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5b2a95135b7f0c9b"
                ></script>
              </div>
              <article>
                <div className="row">
                  <div className="col-lg-4 col-6">
                    <a
                      href="//acceso.comprasdominicana.gob.do/compras/publicaciones/consultas/procesosdecompras/consulta.jsp?hiddenTipoConsulta=EN_CURSO"
                      target="_blank"
                      className="box"
                    >
                      <div className="icon">
                        <img src="/assets/img/c_01.svg" />
                      </div>
                      <span className="title">
                        Procesos
                        <br /> en curso
                      </span>
                    </a>
                  </div>
                  <div className="col-lg-4 col-6">
                    <a
                      href="//acceso.comprasdominicana.gob.do/compras/publicaciones/consultas/procesosdecompras/consulta.jsp?hiddenTipoConsulta=FINALIZADOS"
                      target="_blank"
                      className="box"
                    >
                      <div className="icon">
                        <img src="/assets/img/c_02.svg" />
                      </div>
                      <span className="title">
                        Procesos
                        <br /> finalizados
                      </span>
                    </a>
                  </div>
                  <div className="col-lg-4 col-6">
                    <a
                      href="//acceso.comprasdominicana.gob.do/compras/publicaciones/consultas/procesosdecompras/consulta.jsp?hiddenTipoConsulta=EMERGENCIAS"
                      target="_blank"
                      className="box"
                    >
                      <div className="icon">
                        <img src="/assets/img/c_03.svg" />
                      </div>
                      <span className="title">
                        Procesos
                        <br /> de emergencia
                      </span>
                    </a>
                  </div>
                  <div className="col-lg-4 col-6">
                    <a
                      href="//acceso.comprasdominicana.gob.do/compras/publicaciones/consultas/procesosdecompras/consulta.jsp?hiddenTipoConsulta=MIPYMES"
                      target="_blank"
                      className="box"
                    >
                      <div className="icon">
                        <img src="/assets/img/c_04.svg" />
                      </div>
                      <span className="title">
                        Procesos
                        <br /> MiPymes
                      </span>
                    </a>
                  </div>
                  <div className="col-lg-4 col-6">
                    <a
                      href="//acceso.comprasdominicana.gob.do/compras/publicaciones/consultas/procesosdecompras/consulta.jsp?hiddenTipoConsulta=EN_EVALUACION"
                      target="_blank"
                      className="box"
                    >
                      <div className="icon">
                        <img src="/assets/img/c_05.svg" />
                      </div>
                      <span className="title">
                        Procesos
                        <br /> en evaluación
                      </span>
                    </a>
                  </div>
                  <div className="col-lg-4 col-6">
                    <a
                      href="//acceso.comprasdominicana.gob.do/compras/publicaciones/consultas/procesosdecompras/consulta.jsp?hiddenTipoConsulta=URGENCIAS"
                      target="_blank"
                      className="box"
                    >
                      <div className="icon">
                        <img src="/assets/img/c_06.svg" />
                      </div>
                      <span className="title">
                        Procesos
                        <br /> de Urgencia
                      </span>
                    </a>
                  </div>
                </div>
              </article>
            </section>
          </div>
        </div>
      </main>
    </Fragment>
  );
}
