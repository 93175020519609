import React from "react";

export default function ArchiveCard({ item }) {
  return (
    <div className="attachment__container">
      <div className="attachment__container_item">
        <div className="the-icon">
          <div alt={item.url} style={{ position: "relative" }}>
            <i
              className="fas fa-file"
              style={{ fontSize: "80px", color: "#D6D6D6" }}
            ></i>
            <p
              style={{
                position: "absolute",
                top: "30px",
                width: "80%",
                textAlign: "center",
                color: "white",
                textTransform: "uppercase",
              }}
            >
              {item.file_ext}
            </p>
          </div>
        </div>

        <div className="the-caption">
          <b className="title">{item.nombre}</b>
          <div className="">
            <span>{item.descripcion}</span>
            <br />
            <span>Tamaño:&nbsp; {Math.round(item.file_size / 1024)}Kb</span>
            &nbsp; Fecha de subida: &nbsp;{item.creado}
            <br />
          </div>
        </div>

        <div className="the-btn">
          <a
            rel="noreferrer"
            href={item.file_url}
            target="_blank"
            className="btn-descargar"
            style={{ cursor: "pointer" }}
          >
            <span className="hidden-xs">Descargar</span>
          </a>
        </div>
      </div>
    </div>
  );
}
