import "./styles.css";

import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import url from "../../url";
import Loading from "../Loading";

export default function AsideMenu({ image, id }) {
  const [menu, setmenu] = useState([]);
  const [isLoading, setisLoading] = useState(false);

  const history = useHistory();
  const params = useParams();

  const isInSubmenu = (menuid, menus) => {
    for (let menu = 0; menu < menus.length; menu++) {
      const element = menus[menu];
      if (element.submenus?.some((ob) => parseInt(ob.id) === parseInt(menuid)))
        return true;

      if (element.submenus?.length) {
        isInSubmenu(menuid, element.submenus);
      }
    }
    return false;
  };

  const redirectTo = (menuId) => {
    fetch(`${url}urls?menu=${menuId}&&institucion=${params.institucionid}`, {
      method: "get",
    })
      .then(async (res) => {
        const data = await res.json();
        const d = data?.data;
        const f = (d || []).length ? d[0] : null;
        if (!f) return;
        window.open(f?.url, "_blank");
      })
      .catch(() => {
        return;
      });
  };

  useEffect(() => {
    setisLoading(true);
    fetch(`${url}menus`, {
      method: "get",
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        setmenu(res.data || []);
        setisLoading(false);
      })
      .catch(() => {
        setisLoading(false);
      });
  }, []);

  return (
    <div className="asidemenuadmin">
      {/* <img
        className="logonav"
        alt="Logo de transparencia"
        src={image || "/assets/img/noimagen.jpeg"}
      /> */}
      <ul>
        {isLoading === "loading" && <Loading />}
        {menu?.map((obj, i) => {
          if (!isInSubmenu(obj.id, menu))
            return (
              <li
                key={i}
                onClick={() => {
                  if (obj.tipo === "url") return redirectTo(obj.id);
                  !obj.submenus?.length &&
                    history.push(`/institucion/${id}/files/${obj.slug}}`);
                }}
              >
                {obj.nombre}
                {obj.submenus.length ? (
                  <i className="fas fa-caret-right"></i>
                ) : null}
                {obj.submenus.length ? (
                  <ul>
                    {obj.submenus.map((object, ind) => {
                      return (
                        <li
                          key={ind}
                          onClick={() => {
                            if (object.tipo === "url")
                              return redirectTo(object.id);
                            if (object.slug === "46-nominas-de-empleados") {
                              return history.push(`/institucion/${id}/nomina`);
                            }

                            history.push(
                              `/institucion/${id}/files/${object.slug}_&_${obj.slug}}`
                            );
                          }}
                        >
                          {object.nombre}
                        </li>
                      );
                    })}
                  </ul>
                ) : null}
              </li>
            );

          return null;
        })}
      </ul>
    </div>
  );
}
