import * as ExcelJS from "exceljs/dist/exceljs.min.js";
import * as FileSaver from "file-saver";

export default async function createExcel({
  header,
  items,
  keys,
  excelName = "nomina",
}) {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("");
  worksheet.state = "visible";

  const objRow = {
    1: "A",
    2: "B",
    3: "C",
    4: "D",
    5: "E",
    6: "F",
    7: "G",
    8: "H",
    9: "I",
    10: "J",
    11: "K",
    12: "L",
    13: "M",
    14: "N",
    15: "O",
    16: "P",
    17: "Q",
    18: "R",
    19: "S",
    20: "T",
    21: "U",
    22: "V",
    23: "W",
    24: "X",
    25: "Y",
    26: "Z",
  };

  header.map((it, index) => {
    const idx = index + 1;
    worksheet.getCell(`${objRow[idx]}${1}`).value = it;
    return null;
  });

  let initialRow = 3;

  for (const item of items) {
    // eslint-disable-next-line no-loop-func
    keys.map((key, index) => {
      worksheet.getCell(`${objRow[index + 1]}${initialRow}`).value = item[key];
      return null;
    });

    initialRow += 1;
  }

  const blobType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const excelType = await workbook.xlsx.writeBuffer();
  const excel = new Blob([excelType], { type: blobType });
  FileSaver.saveAs(excel, `${excelName}.xlsx`);
}
