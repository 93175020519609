import { useEffect, useState } from "react";
import { useRef } from "react";
import { useParams } from "react-router";
import { FilterArray2 } from "../../../Utils/filterArray";

import Loading from "../../../components/Loading";
import url from "../../../url";
import Legend from "../accessories/legend";
import Tag from "../accessories/tag";
import InstitutionFolders from "./institutionFolders";
import ArchiveCard from "../accessories/archivos-card";

export const InstitucionArchivos = () => {
  const [files, setFiles] = useState([]);
  const [files2, setFiles2] = useState([]);
  const [tags, setTags] = useState([]);
  const [selecteds, setSelecteds] = useState([]);

  const [isLoadingFiles, SetisLoadingFiles] = useState(false);
  const [thereLegend, setThereLegend] = useState(false);

  const param = useParams();
  const isNomina = param.slugmenu === "isNomina}";
  const ressubtitle = useRef();

  const menuId = (param.slugmenu || "").split("-");

  const currentNav = (param.slugmenu || "")
    .replaceAll("-", " ")
    .split("_&_")
    .reverse()
    .map((it) => {
      const item = it.split(" ");
      item.shift();
      return item.join(" ").replace("}", "");
    })
    .join(" / ");

  const institutionId = param.institucionid;

  const onSelectTag = (value) => {
    const found = selecteds.includes(value);
    if (found) setSelecteds((items) => items.filter((it) => it !== value));
    if (!found) setSelecteds((items) => [...items, value]);
  };

  const onFilterByTags = (items = []) => {
    if (!selecteds.length) return items;
    const objTags = selecteds.reduce(
      (acc, curr) => ({ ...acc, [curr]: curr }),
      {}
    );
    return items.filter((item) => !!objTags[item.tag]);
  };

  const onFilterByInput = (value) => {
    const items = FilterArray2(files, value, ["nombre"]);
    return setFiles2(onFilterByTags(items));
  };

  const GetFiles = async () => {
    try {
      SetisLoadingFiles(true);
      const res = await fetch(
        `${url}archivos?institucion=${institutionId}&menu=${menuId[0]}`,
        {
          method: "get",
          headers: new Headers({}),
        }
      );
      const json = await res.json();
      setFiles(json.data);
      setFiles2(json.data);
      setTags([...new Set((json?.data || []).map((item) => item.tag))]);
      SetisLoadingFiles(false);
    } catch (error) {
      SetisLoadingFiles(false);
    }
  };

  useEffect(() => {
    if (!selecteds.length) {
      setFiles2(files);
    }
    if (selecteds.length) {
      const objTags = selecteds.reduce(
        (acc, curr) => ({ ...acc, [curr]: curr }),
        {}
      );
      setFiles2(files.filter((item) => !!objTags[item.tag]));
    }
    //eslint-disable-next-line
  }, [selecteds]);

  useEffect(() => {
    window.scroll({
      top: 150,
      behavior: "smooth",
    });
  }, [param.slugmenu]);

  useEffect(() => {
    !isNomina && GetFiles(param.institucionid, param.slugmenu);
    //eslint-disable-next-line
  }, [param]);

  const data = files2.reduce(
    (acc, curr) => {
      let haveFolder = !!curr.folder?.id;

      return {
        ...acc,
        [haveFolder ? "folders" : "files"]: haveFolder
          ? {
              ...acc.folders,
              [curr.folder.id]: {
                ...curr.folder,
                files: [
                  ...((acc.folders[curr.folder.id] || {}).files || []),
                  curr,
                ],
              },
            }
          : [...acc.files, curr],
      };
    },
    {
      files: [],
      folders: {},
    }
  );

  return (
    <>
      <h6 ref={ressubtitle}>{param.categoria} </h6>
      <h6>Estás aqui: {currentNav} </h6>

      <div style={{ marginTop: 20, marginBottom: 20 }}>
        <Legend
          menuId={menuId[0]}
          institutionId={institutionId}
          isThereLegend={(legend) => setThereLegend(!!legend)}
        />
      </div>

      {!thereLegend && files?.length < 1 && (
        <img
          alt=""
          style={{ width: 100 + "%" }}
          src="/assets/img/BannerMigracion.png"
        />
      )}
      {isLoadingFiles && <Loading />}
      {files.length ? (
        <div className="mr-3 mt-5 mb-3">
          <div className="d-flex ">
            <input
              placeholder="Buscar archivo"
              onChange={({ target }) => onFilterByInput(target.value)}
            />
          </div>

          <div>
            <div className="d-flex">
              <Tag
                active={!selecteds.length}
                value="Mostar todos"
                onClick={() => setSelecteds([])}
              />
              {tags.map((it) => {
                const objTags = selecteds.reduce(
                  (acc, curr) => ({ ...acc, [curr]: curr }),
                  {}
                );

                return (
                  <Tag
                    active={it && objTags[it]}
                    value={it}
                    onClick={onSelectTag}
                  />
                );
              })}
            </div>
          </div>
        </div>
      ) : null}

      <InstitutionFolders folders={Object.values(data.folders)} />
      {data.files.map((obj, i) => {
        return <ArchiveCard item={obj} key={i} />;
      })}
    </>
  );
};
