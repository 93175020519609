import React, { useEffect } from 'react';
import Navbar from './navbar'
import { Link } from 'react-router-dom'
import $ from "jquery"

export default function Header(props) {
    useEffect(() => {
        $(".mobile-menu").click(function () {
            $(".la-menu").slideToggle();
        });
    }, [])
    return (
        <header id="top">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-9 col-9">
                        <div className="logo">
                            <Link to="/index" alt="Logo">
                                <img src="/assets/img/logo(2).svg" alt="Logo Portal Único de Transparencia" />
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-3 d-none d-sm-none d-lg-block">
                        <div className="eletop">
                            <div className="escudo">
                                <img src="/assets/img/rd.png" alt='' />
                            </div>
                            <div className="search">
                                { /* <form className="search" method="get" role="search">
                                    <span className="input-la input-group">
                                        <input className="search-input form-control" aria-label="Ingresa tu búsqueda" type="search" required name="s" placeholder="Ingresa tu búsqueda" />
                                        <div className="input-group-btn">
                                            <button className="search-submit" aria-label="Buscar en la página" type="submit" role="button"><i className="fa fa-search" ></i></button>
                                        </div>
                                    </span>
                                </form>*/}
                            </div>
                        </div>
                    </div>

                    <div className="col-3 d-lg-none d-md-none d-block">
                        <div className="mobile-menu btn">
                            <i className="fa fa-bars mr-1"></i>Menú
                    </div>
                    </div>
                </div>
            </div>

            <Navbar
                active={props.active}
            />

        </header>
    )

}