import { useRef, Fragment } from "react";
import PaginationControlled from "../../../components/paginations";
import Tpdf from "../../../components/topdf";

const estatusEmpleados = [
  { TIPO_EMPLEADO: "CONTRATADO" },
  { TIPO_EMPLEADO: "CONTRATADO CON SEGURIDA SOCIAL" },
  { TIPO_EMPLEADO: "CONTRATADO EN PRUEBA" },
  { TIPO_EMPLEADO: "CONTRATADO EN SERVICIOS" },
  { TIPO_EMPLEADO: "CONTRATADO SERVICIOS DIVERSOS" },
  { TIPO_EMPLEADO: "CONTRATADO SIN SEGURIDA SOCIAL" },
  { TIPO_EMPLEADO: "CONTRATADO Y/O IGUALADO" },
  { TIPO_EMPLEADO: "CONTRATADOS 10%" },
  { TIPO_EMPLEADO: "CONTRATADOS CARGOS DE CARRERA" },
  { TIPO_EMPLEADO: "CONTRATADOS PREPARA" },
  { TIPO_EMPLEADO: "CONTRATADOS TEMPOREROS" },
  { TIPO_EMPLEADO: "FIJO" },
  { TIPO_EMPLEADO: "HONORARIOS PROFESIONALES" },
  { TIPO_EMPLEADO: "JORNALES" },
  { TIPO_EMPLEADO: "JORNALES CON SEGURIDAD SOCIAL" },
  { TIPO_EMPLEADO: "MILITAR FIJO" },
  { TIPO_EMPLEADO: "NOMINAL" },
  { TIPO_EMPLEADO: "NOMINAL PERIODO DE PRUEBA" },
  { TIPO_EMPLEADO: "PASANTE" },
  { TIPO_EMPLEADO: "PERSONAL DE CARACTER EVENTUAL" },
  { TIPO_EMPLEADO: "PERSONAL DE VIGILANCIA" },
  { TIPO_EMPLEADO: "PERSONAL DOCENTE" },
  { TIPO_EMPLEADO: "PERSONAL MILITAR (TECNICO)" },
  { TIPO_EMPLEADO: "SERVICIOS ESPECIALES" },
  { TIPO_EMPLEADO: "TRAMITE DE PENSION" },
];

export function NominaTable({
  searchParams,
  pagination,
  payroll = [],
  payrollDate = [],
  onChange,
  periodSelected,
  onSearch,
  onExport,
}) {
  const tableNomina = useRef(null);

  return (
    <Fragment>
      <h1 className="page__title mt-5">Nómina general de la institución </h1>
      <div className="page__control">
        <div className="tools" style={{ height: 45 }}>
          <div className="d-flex align-items-center">
            <span>Nómina&nbsp;&nbsp;</span>
            <select
              value={periodSelected}
              style={{
                height: 35,
                border: "0.0625rem solid #e9e9e9",
                padding: 0,
                paddingLeft: 10,
              }}
              onChange={(e) => {
                onChange("periodSelected", e.target.value);
              }}
            >
              {(payrollDate || []).map((obj, i) => {
                return (
                  <option key={i} value={obj.codigo}>
                    {obj.descripcion}
                  </option>
                );
              })}
            </select>
          </div>

          <div
            className="d-flex align-items-center justify-content-between col-4"
            style={{ fontSize: 20 }}
          >
            <button
              style={{
                border: "none",
                background: "#2d572c",
                color: "#fff",
                fontSize: 13,
                padding: 8,
                borderRadius: 5,
                fontWeight: 600,
              }}
              onClick={onExport}
            >
              Exportar a XLSX
            </button>
            {/* <Toexel
              referencia={tableNomina.current}
              filename="Nómina"
              buttonText={"Exportar a XLS"}
            /> */}

            <Tpdf referencia={tableNomina.current} filename="Nómina" />
          </div>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table table-hover " ref={tableNomina}>
          <thead>
            <tr>
              <td>
                <input
                  className="form-control form-control-sm"
                  type="text"
                  name="nombres"
                  placeholder="Nombre"
                  onChange={({ target }) => onChange(target.name, target.value)}
                />
              </td>
              <td>
                {" "}
                <input
                  className="form-control form-control-sm"
                  type="text"
                  name="apellidos"
                  placeholder="Apellido"
                  onChange={({ target }) => onChange(target.name, target.value)}
                />
              </td>
              <td>
                <input
                  className="form-control form-control-sm"
                  type="text"
                  name="cargo"
                  placeholder="Función"
                  onChange={({ target }) => onChange(target.name, target.value)}
                />
              </td>
              <td>
                <input
                  className="form-control form-control-sm"
                  type="text"
                  name="lugar"
                  placeholder="Área"
                  onChange={({ target }) => onChange(target.name, target.value)}
                />
              </td>
              <td>
                <select
                  className="form-control form-control-sm"
                  name="genero"
                  onChange={({ target }) => onChange(target.name, target.value)}
                >
                  <option value="">Ambos</option>
                  <option value="MASCULINO">Masculino</option>
                  <option value="FEMENINO">Femenino</option>
                </select>
              </td>
              <td>
                <select
                  className="form-control form-control-sm"
                  name="estatus"
                  onChange={({ target }) => onChange(target.name, target.value)}
                >
                  <option value="">Todos</option>
                  {estatusEmpleados.map((d, i) => {
                    return (
                      <option value={d.TIPO_EMPLEADO} key={i}>
                        {d.TIPO_EMPLEADO}
                      </option>
                    );
                  })}
                </select>
              </td>
              <td
                style={{
                  display: "flex",
                  minWidth: 150,
                  justifyContent: "space-around",
                }}
              >
                <button
                  onClick={onSearch}
                  style={{
                    paddingTop: 5 + "px",
                    paddingRight: 10 + "px",
                    paddingLeft: 10 + "px",
                    background: "#003876",
                    color: "#fff",
                    border: "none",
                    borderRadius: 5 + "px",
                  }}
                >
                  <i className="fa fa-search"></i>
                </button>
                <button
                  style={{
                    paddingTop: 5 + "px",
                    paddingRight: 10 + "px",
                    paddingLeft: 10 + "px",
                    background: "#003876",
                    color: "#fff",
                    border: "none",
                    borderRadius: 5 + "px",
                  }}
                >
                  <i className="fa fa-redo"></i>
                </button>
              </td>
            </tr>
            <tr>
              <th scope="col">Nombres</th>
              <th scope="col">Apellidos</th>
              <th scope="col">Institución</th>
              <th scope="col">Función</th>
              <th scope="col">Área</th>
              <th scope="col">Género</th>
              <th scope="col">Estatus</th>
              <th scope="col">Sueldo bruto</th>
              <th scope="col"></th>
            </tr>
          </thead>

          <tbody>
            {(payroll || [, payrollDate]).map((d, i) => {
              return (
                <tr key={i}>
                  <td>{d.NOMBRES}</td>
                  <td>{d.APELLIDOS}</td>
                  <td>{d.INSTITUCION}</td>
                  <td>{d.CARGO}</td>
                  <td>{d.LUGAR_FUNCIONES}</td>
                  <td>{d.GENERO}</td>
                  <td>{d.TIPO_EMPLEADO}</td>
                  <td>{"RD$" + d.SUELDO_BRUTO_FIJOS}</td>
                  <td></td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <div style={{ display: "flex" }}>
          <PaginationControlled
            eventChange={(_, value) => onChange("page", value)}
            page={searchParams.page || 1}
            count={pagination.meta?.last_page || 1}
          />
          <b>
            <p style={{ padding: 10 }}>
              Resultados del {pagination.meta?.from || 1} al{" "}
              {pagination.meta?.to || 0},Total:{pagination.meta?.total || 0}
            </p>
          </b>
        </div>
      </div>
    </Fragment>
  );
}
