import { useEffect, useState } from "react";
import url from "../../../url";

export default function Legend({ menuId, institutionId, isThereLegend }) {
  const [data, setData] = useState([]);

  useEffect(() => {
    const getLegend = async () => {
      const fetched = await fetch(
        `${url}leyendas?institucion=${institutionId}&&menu=${menuId}`
      );
      const json = await fetched.json();

      const legend = (json.data || []).length ? json.data[0] : {};

      isThereLegend(!!Object.keys(legend).length);
      setData(legend);
    };
    getLegend();
    //eslint-disable-next-line
  }, [menuId]);

  return (
    <div>
      {data.leyenda && (
        <>
          <span
            style={{
              display: "flex",
            }}
          >
            {data.titulo && (
              <p
                style={{
                  marginRight: 3,
                }}
              >
                {" "}
                {data.titulo}
              </p>
            )}
            {data.titulo && data.url && <p>-</p>}
            {data.url && (
              <p
                style={{ color: "blue", marginLeft: 3, cursor: "pointer" }}
                className="text-blue-500"
                onClick={() => window.open(data.url, "_blank")}
              >
                {" "}
                {data.url}
              </p>
            )}
          </span>
          <div dangerouslySetInnerHTML={{ __html: data?.leyenda }}></div>
        </>
      )}
    </div>
  );
}
