import MenuItem from "./MenuItem";

export default function AsideBarinstituciones(props) {
  return (
    <div className="more">
      <h3 className="front__type_title">PODERES y organismos</h3>
      <div className="content">
        <ul>
          <MenuItem
            current={props.active}
            dir="/instituciones"
            name="Todos"
          />
          <MenuItem
            current={props.active}
            dir="/instituciones/Poder Ejecutivo"
            name="Poder Ejecutivo"
          />
          <MenuItem
            current={props.active}
            dir="/instituciones/Poder Legislativo"
            name="Poder Legislativo"
          />
          <MenuItem
            current={props.active}
            dir="/instituciones/Poder Judicial"
            name="Poder Judicial"
          />
        </ul>
      </div>
    </div>
  );
}
