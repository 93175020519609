import createExcel from "./create-excel";

export default function exportTableNominaToExcel(items) {
  return createExcel({
    items,
    header: [
      "Nombres",
      "Apellidos",
      "Insitución",
      "Función",
      "Área",
      "Genero",
      "Estatus",
      "Sueldo Bruto",
    ],
    keys: [
      "NOMBRES",
      "APELLIDOS",
      "INSTITUCION",
      "CARGO",
      "LUGAR_FUNCIONES",
      "GENERO",
      "TIPO_EMPLEADO",
      "SUELDO_BRUTO_FIJOS",
    ],
  });
}
