import React from "react";
import Footer from "../Footer";
import Header from "../header";
import Home from "../../pages/Home";
import { Switch } from "react-router-dom";
import { Route } from "react-router";
import Institucions from "../../pages/Instituciones";
import InstitucionsDetails from "../../pages/institucionDetalles";
import PageNotFound from "../../pages/404";
import Consulta from "../../pages/Consultas";
import LeyGenral from "../../pages/LeyGeneral";
import Compras from "../../pages/Compras";
import Contacto from "../../pages/Contacto";
import Tramsparencia from "../../pages/transparencia";
import InfoRequest from "../../pages/SolisitudDeInformacion";
import AtencionCiudadana from "../../pages/AtencionCiudadana";

export default function CustonLayout() {
  return (
    <div>
      <Header />
      <Switch>
        <Route path="/" exact>
          <Home />
        </Route>
        <Route path="/index" exact>
          <Home />
        </Route>
        <Route path="/Index" exact>
          <Home />
        </Route>
        <Route path="/instituciones/:type?" exact>
          <Institucions />
        </Route>
        <Route path="/institucion/:institucionid">
          <InstitucionsDetails />
        </Route>
        <Route path="/consultas/:search">
          <Consulta />
        </Route>
        <Route exact path="/inicio/transparenciaenlaestrategia">
          <LeyGenral />
        </Route>

        <Route exact path="/inicio/marcolegaldetransparencia">
          <Tramsparencia />
        </Route>

        <Route exact path="/Compras">
          <Compras />
        </Route>

        <Route exact path="/Contacto">
          <Contacto />
        </Route>
        <Route exact path="/atencion_ciudadana/:article?">
          <AtencionCiudadana />
        </Route>
        <Route exact path="/solicitud_de_informacion">
          <InfoRequest />
        </Route>
        <Route>
          <PageNotFound />
        </Route>
      </Switch>
      <Footer />
    </div>
  );
}
