import { Fragment } from "react";
import { Link } from "react-router-dom";

export default function LeyGenral() {
  window.scrollTo(0, 0);

  return (
    <Fragment>
      <div
        className="page-cover"
        style={{
          backgroundImage: "url('/assets/img/pages/estrategia2030.jpg')",
        }}
      ></div>
      <main role="main" className="container">
        <div className="row">
          <div className="col-lg-9">
            <section className="page">
              <div className="page__breadcrumb1s">
                <span className="page__breadcrumb1s_here">
                  Estás aquí:&nbsp;
                </span>
                <span className="page__breadcrumb1s_home">
                  <Link to="/Index">Inicio</Link>&nbsp;/&nbsp;
                </span>
                <span>Estrategia Nacional de Desarrollo 2030</span>
              </div>

              <h1 className="page__title">
                Estrategia Nacional de Desarrollo 2030
              </h1>

              <div className="page__control">
                <script
                  type="text/javascript"
                  src="//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5b2a95135b7f0c9b"
                ></script>
                <div className="tools">
                  <div className="addthis_inline_share_toolbox"></div>
                </div>
              </div>

              <article>
                La Ley Orgánica de la Estrategia Nacional de Desarrollo de la
                República Dominicana 2030 (END), Ley 1-12, está llamada a marcar
                un hito en la forma de conducir la Nación. Como acertadamente se
                planteó en el documento que dio soporte a la primera propuesta
                de anteproyecto de ley, la puesta en ejecución de la END
                constituirá Un viaje de transformación hacia un país mejor. En
                esencia, la END es una ruta consensuada que establece a dónde
                queremos llegar como Nación y traza las pautas de cómo lograrlo.
                <br />
                <br />
                La elaboración y promulgación de esta ley responde no solo a un
                mandato constitucional (Artículo 241) y legal (Ley 498-06).
                Responde sobre todo a una necesidad sentida desde hace tiempo y
                formulada por distintos sectores económicos y sociales: la
                necesidad de imprimir un cambio en el modelo de desarrollo,
                mediante la definición clara de hacia dónde se quiere conducir
                el país en el largo plazo y cómo se hará.
                <br />
                <br />
                El balance de los resultados del desarrollo de República
                Dominicana durante los últimos 40 años, si bien arroja grandes
                éxitos, evidencia también importantes deficiencias. El notable
                crecimiento económico experimentado por el país no genera
                suficientes empleos dignos, por lo que no ha logrado reducir
                sustancialmente la pobreza. En adición, da muestras de una falta
                de competitividad internacional que genera fuertes
                desequilibrios externos y amenaza las posibilidades futuras.
                <br />
                <br />
                Otras áreas de la vida nacional también requieren notables
                cambios: instituciones más eficientes y transparentes son
                necesarias para fortalecer la democracia y para apoyar el
                desarrollo del aparato productivo. Igualmente se requiere una
                mejor gestión de los recursos naturales, fortalecer la gestión
                de riesgos y promover la adaptación a los efectos del cambio
                climático, para dar sostenibilidad al desarrollo futuro.
                <br />
                <br />
              </article>
            </section>
          </div>
        </div>
      </main>
    </Fragment>
  );
}
