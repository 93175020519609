
const Notificacion=(props)=>{
    return <div className="infos red">
                <h2>{props.title}</h2>
                <div className="body">
                    {props.body}
                </div>
            </div>
}

export default Notificacion