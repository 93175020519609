import React, { useState } from "react";
import { Fragment } from 'react'

export default function Contacto() {
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");

    console.log(email, name, subject, message);
    return (
        <Fragment>
            <main role="main" className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <section className="page">
                            <div className="page__breadcrumb1s">
                                <span className="page__breadcrumb1s_here">Estás aquí:&nbsp;</span>
                                <span className="page__breadcrumb1s_home"><a href="index.html">Inicio</a>&nbsp;/&nbsp;</span>
                                <span>Contacto</span>
                            </div>

                            <h1 className="page__title">Contacto</h1>
                            <div className="page__control">
                                <script type="text/javascript" src="//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5b2a95135b7f0c9b"></script>
                            </div>
                            <article>
                            <div className="row">
                                <div className='col-md-3'>
                                <address><b>Dirección: </b> Ave. Mexico # 419, Esq. Leopoldo Navarro, Edificio de Oficinas Gubernamentales Juan Pablo Duarte, Piso #12, Gazcue, Distrito Nacional, R.D.</address>

                                <div className="contact-info">
                                    <span><b>Telefono: </b>809-685-7135</span><br/><br/>
                                    <span><b>Correo: </b>info@digeig.gob.do</span>
                            
                                </div>

                                </div>

                                <div className='col-md-9'>
                                    <iframe
                                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15136.76993488293!2d-69.906096!3d18.4749384!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x4a0825a8ee489fac!2sDirecci%C3%B3n%20General%20de%20%C3%89tica%20e%20Integridad%20Gubernamental!5e0!3m2!1ses!2sdo!4v1614321074322!5m2!1ses!2sdo"
                                        width="100%"
                                        height="450"
                                        style={{ border: 0 }}
                                        allowFullScreen=""
                                        loading="lazy" />

                                </div>

                            </div>
                                
                                <div className="more">
                                    <h3>Formulario de contacto</h3>
                                    <div className="content">
                                        <form id="contact-form" method="POST">
                                            <div className="row">
                                                <div className="col-6">
                                                    <input type="text" onChange={e => setName({ name: e.target.value })} required placeholder="Nombre completo" />
                                                </div>
                                                <div className="col-6">
                                                    <input type="email" onChange={e => setEmail({ email: e.target.value })} required placeholder="Correo electrónico" aria-describedby="emailHelp" />
                                                </div>
                                            </div>
                                            <div className="row mt-3">
                                                <div className="col-12">
                                                    <input type="text" onChange={e => setSubject({ subject: e.target.value })} placeholder="Asunto" />
                                                </div>
                                            </div>
                                            <div className="row mt-3">
                                                <div className="col-12">
                                                    <textarea onChange={e => setMessage({ message: e.target.value })} placeholder="Mensaje" rows="5"></textarea>
                                                </div>
                                            </div>
                                            <div className="row mt-3">
                                                <div className="col-12">
                                                    <button type="submit" className="btn">Enviar mensaje</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </article>
                        </section>
                    </div>
                </div>
            </main>
        </Fragment >

    );
}