import { Link, Route, Switch } from "react-router-dom";
import { InstitucionArchivos } from "./tampletes/institucionArchivos";

import Loading from "../../components/Loading";
import AsideMenu from "../../components/AsideMenu2";
import Notificacion from "../../components/Notificacion";
import InstitucionNomina from "./tampletes/institucionNomina";

import useActions from "./useActions";

export default function InstitucionsDetails() {
  const { institucion, type, RequestStatus, loading, institucionid } =
    useActions();

  return (
    <>
      <div
        className="page-cover"
        style={{ backgroundImage: "url('/assets/img/instituciones.jpg')" }}
      ></div>

      <main role="main" className="container-fluid">
        <div className="row">
          <div className="col-lg-3 d-flex flex-column">
            {institucion?.logo_url && (
              <img
                src={institucion.logo_url || ""}
                alt="logo institucion"
                className="align-self-center"
                width={230}
                style={{ marginTop: 40, borderRadius: "5%" }}
              />
            )}
            <div className="mt-4">
              <AsideMenu
                logo={institucion?.logo}
                id={institucionid}
                setFiles={"f"}
              />
            </div>
          </div>
          <div className="col-lg-9">
            <section className="page">
              <div className="page__breadcrumb1s">
                <span className="page__breadcrumb1s_here">
                  Estás aquí:&nbsp;
                </span>
                <span className="page__breadcrumb1s_home">
                  <Link to="/Index">Inicio</Link>&nbsp;/&nbsp;
                </span>
                <span className="page__breadcrumb1s_home">
                  <Link to="/instituciones">Instituciones</Link>&nbsp;/&nbsp;
                </span>
                <span>{type}</span>
              </div>
              <h1 className="page__title">{type}</h1>
              <article>
                {loading && <Loading />}
                <>
                  <main role="main" className="container">
                    <div className="row">
                      <div className="col-lg-12">
                        <section className="page services">
                          {RequestStatus && (
                            <Notificacion
                              title="Upps, Ocurrio un error en su solicitud"
                              body="Por favor intentelo nuevamente"
                            />
                          )}
                          <div className="row space-x-2">
                            <h1 className="page__title">
                              {institucion?.nombre}
                            </h1>
                          </div>
                          <Switch>
                            <Route
                              exact
                              path={`/institucion/:institucionid/files/:slugmenu`}
                            >
                              <InstitucionArchivos />
                            </Route>
                            <Route
                              exact
                              path={`/institucion/:institucionid/nomina`}
                            >
                              <InstitucionNomina />
                            </Route>
                            <Route>
                              <div className="page__control"></div>
                              <article>
                                <div className="btninfo">
                                  <div className="item">
                                    <img
                                      src="/assets/img/telefono.svg"
                                      alt=""
                                    />
                                    <div>
                                      <h3>Teléfono</h3>
                                      <span>{institucion?.telefono || ""}</span>
                                    </div>
                                  </div>
                                  <div className="item">
                                    <img src="/assets/img/horario.svg" alt="" />
                                    <div>
                                      <h3>Horario</h3>
                                      <span>{institucion?.horario || ""}</span>
                                    </div>
                                  </div>
                                  <div className="item">
                                    <img src="/assets/img/link.svg" alt="" />
                                    <div>
                                      <h3>URL</h3>
                                      <span>
                                        <a
                                          className="link-portal"
                                          target="_blank"
                                          rel="noreferrer"
                                          href={institucion?.url || ""}
                                        >
                                          Ir al Portal
                                        </a>
                                      </span>
                                    </div>
                                  </div>
                                  <div className="item">
                                    <img
                                      src="/assets/img/direccion.svg"
                                      alt=""
                                    />
                                    <div>
                                      <h3>Dirección</h3>
                                      <span>
                                        {institucion?.direccion || ""}
                                      </span>
                                    </div>
                                  </div>
                                </div>

                                <div className="social">
                                  <h2>Redes sociales</h2>
                                  <div className="redes_sociales">
                                    <div className="redes">
                                      {institucion?.facebook && (
                                        <a
                                          href={institucion?.facebook || "#"}
                                          className="rs facebook"
                                          target="_blank"
                                          aria-label="Facebook"
                                          rel="noreferrer"
                                        >
                                          <div className="rds">
                                            <i className="fab fa-facebook"></i>
                                            <span className="text">
                                              Facebook
                                            </span>
                                          </div>
                                        </a>
                                      )}
                                      {institucion?.twitter && (
                                        <a
                                          href={institucion?.twitter || "#"}
                                          className="rs twitter"
                                          target="_blank"
                                          aria-label="Twitter"
                                          rel="noreferrer"
                                        >
                                          <div className="rds">
                                            <i className="fab fa-twitter"></i>
                                            <span className="text">
                                              Twitter
                                            </span>
                                          </div>
                                        </a>
                                      )}

                                      {institucion?.youtube && (
                                        <a
                                          href={institucion?.youtube || "#"}
                                          className="rs youtube"
                                          target="_blank"
                                          aria-label="Youtube"
                                          rel="noreferrer"
                                        >
                                          <div className="rds">
                                            <i className="fab fa-youtube"></i>
                                            <span className="text">
                                              Youtube
                                            </span>
                                          </div>
                                        </a>
                                      )}

                                      {institucion?.instagram && (
                                        <a
                                          href={institucion?.instagram || "#"}
                                          className="rs instagram"
                                          target="_blank"
                                          aria-label="Instagram"
                                          rel="noreferrer"
                                        >
                                          <div className="rds">
                                            <i className="fab fa-instagram"></i>
                                            <span className="text">
                                              Instagram
                                            </span>
                                          </div>
                                        </a>
                                      )}
                                      {institucion?.linkedin && (
                                        <a
                                          href={institucion?.linkedin || "#"}
                                          className="rs linkedin"
                                          target="_blank"
                                          aria-label="linkedin"
                                          rel="noreferrer"
                                        >
                                          <div className="rds">
                                            <i className="fab fa-linkedin"></i>
                                            <span className="text">
                                              LinkedIn
                                            </span>
                                          </div>
                                        </a>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </article>
                            </Route>
                          </Switch>
                        </section>
                      </div>
                    </div>
                  </main>
                </>
              </article>
            </section>
          </div>
        </div>
      </main>
    </>
  );
}
