import React, { useState, useEffect, useContext } from "react";
import URL from "../url";

const configcontext = React.createContext();

export default function ConfigContext({ children }) {
  const [state, setstate] = useState({});
  useEffect(() => {
      fetch(URL + "nominas/fechas",{ 
        method: 'get', 
      })
        .then((res) => {
          return res.json();
        })
        .then((res) => {
          setstate({ ...state, periodos_nomina: res.data });
        })
        .catch((error) => {
          console.log(new Error(error));
        });
  }, []);

  return (
    <configcontext.Provider value={state}>{children}</configcontext.Provider>
  );
}

export function useConfigContext() {
  const context = useContext(configcontext);
  return context;
}
