import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import url from "../../url";

export default function useActions() {
  const [loading, setLoading] = useState(false);
  const [RequestStatus, setRequestStatus] = useState(false);
  const [institucion, setInstitucion] = useState([]);

  const { institucionid } = useParams();
  const params = useParams();

  const GetInstitucion = (url) => {
    setLoading(true);
    fetch(url, {
      method: "get",
      headers: new Headers({}),
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        setInstitucion(res.data);
        setLoading(false);
        setRequestStatus(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        setRequestStatus(true);
      });
  };

  useEffect(() => {
    GetInstitucion(`${url}instituciones/${institucionid}/show`);
    window.scrollTo(0, 0);
  }, []);

  return {
    institucion,
    loading,
    RequestStatus,
    institucionid,
  };
}
