import { Fragment, useState, useEffect, useRef } from "react";
import { useParams, useHistory } from "react-router-dom";
import url from "../url";
import Loading from "../components/Loading";
import Tpdf from "../components/topdf";
import Toexel from "../components/toexel";
import { useConfigContext } from "../Context/ConfigContex";
import PaginationControlled from "../components/paginations";
import * as accessories from "./institucionDetalles/accessories";

const estatusEmpleados = [
  { TIPO_EMPLEADO: "CONTRATADO" },
  { TIPO_EMPLEADO: "CONTRATADO CON SEGURIDA SOCIAL" },
  { TIPO_EMPLEADO: "CONTRATADO EN PRUEBA" },
  { TIPO_EMPLEADO: "CONTRATADO EN SERVICIOS" },
  { TIPO_EMPLEADO: "CONTRATADO SERVICIOS DIVERSOS" },
  { TIPO_EMPLEADO: "CONTRATADO SIN SEGURIDA SOCIAL" },
  { TIPO_EMPLEADO: "CONTRATADO Y/O IGUALADO" },
  { TIPO_EMPLEADO: "CONTRATADOS 10%" },
  { TIPO_EMPLEADO: "CONTRATADOS CARGOS DE CARRERA" },
  { TIPO_EMPLEADO: "CONTRATADOS PREPARA" },
  { TIPO_EMPLEADO: "CONTRATADOS TEMPOREROS" },
  { TIPO_EMPLEADO: "FIJO" },
  { TIPO_EMPLEADO: "HONORARIOS PROFESIONALES" },
  { TIPO_EMPLEADO: "JORNALES" },
  { TIPO_EMPLEADO: "JORNALES CON SEGURIDAD SOCIAL" },
  { TIPO_EMPLEADO: "MILITAR FIJO" },
  { TIPO_EMPLEADO: "NOMINAL" },
  { TIPO_EMPLEADO: "NOMINAL PERIODO DE PRUEBA" },
  { TIPO_EMPLEADO: "PASANTE" },
  { TIPO_EMPLEADO: "PERSONAL DE CARACTER EVENTUAL" },
  { TIPO_EMPLEADO: "PERSONAL DE VIGILANCIA" },
  { TIPO_EMPLEADO: "PERSONAL DOCENTE" },
  { TIPO_EMPLEADO: "PERSONAL MILITAR (TECNICO)" },
  { TIPO_EMPLEADO: "SERVICIOS ESPECIALES" },
  { TIPO_EMPLEADO: "TRAMITE DE PENSION" },
];

const NominaTable = ({ setLoading, loading, setRequestStatus }) => {
  const [filtro, setFiltro] = useState({});
  const [periodo, setPeriodo] = useState();
  const [Nomina, SetNomina] = useState();
  const [zoom, setZoom] = useState(12);
  const [excelStatus, setExcelStatus] = useState("completed");
  const config = useConfigContext();
  const tableNomina = useRef(null);
  const param = useParams();
  const history = useHistory();

  const onExportToExcel = async () => {
    setExcelStatus("loading");

    const { params, isEmptyValue } = accessories.buildAndValidateParams(filtro);

    if (!isEmptyValue) {
      const res = await accessories.fetchRequest(
        accessories.buildQueryParams(
          accessories.buildQuery("nominas/export", {
            periodSelected: periodo,
            periodDefault: GetPeriodo(),
          }),
          params
        )
      );

      res.data && accessories.exportTableNominaToExcel(res.data);
    }

    setExcelStatus("completed");
  };

  const CallRequest = (buscar) => {
    const obj = buscar ? buscar : {};
    let period = periodo || GetPeriodo();
    if (!period) return;
    const form = new FormData();
    form.append("periodo", period);

    const _url = !obj.reset
      ? `${url}nominas?page=${
          obj.paginate || param.pag || 1
        }&periodo=${period}&nombres=${filtro?.nombres || ""}&apellidos=${
          filtro?.apellidos || ""
        }&institucion=${filtro?.institucion || ""}&cargo=${
          filtro?.cargo || ""
        }&lugar=${filtro?.lugar || ""}&genero=${filtro?.genero || ""}&estatus=${
          filtro?.estatus || ""
        }`
      : `${url}nominas?page=${
          obj.paginate || param.pag || 1
        }&periodo=${period}`;

    setLoading(true);
    fetch(_url, {
      headers: new Headers({
        Accept: "application/json",
      }),
      method: "get",
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        SetNomina(res);
        setLoading(false);
        obj.reset && history.push("/consultas/nomina");
        obj.buscar && history.push("/consultas/nomina/1");
        obj.paginate && history.push(`/consultas/nomina/${obj.paginate}`);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        setRequestStatus(true);
      });
  };

  useEffect(() => {
    CallRequest({ paginate: 1 });
  }, [periodo]);

  useEffect(() => {
    if (!GetPeriodo()) return;
    setPeriodo(GetPeriodo());
  }, [config]);

  const GetPeriodo = () => {
    if (!config?.periodos_nomina) return;
    return config?.periodos_nomina[config?.periodos_nomina.length - 1].codigo;
  };

  const HandleFiltrar = (event) => {
    setFiltro({ ...filtro, [event.target.name]: event.target.value });
  };

  const HandlerPagination = (i, value) => {
    CallRequest({ paginate: value || 1 });
  };

  const GetNomina = (id) => {
    history.push(`/instituciones/Oficinas/Nomina/${id}`);
  };

  const handlerReset = async () => {
    setFiltro({});
    CallRequest({ reset: 1 });
  };

  const handlerbuscar = (e) => {
    if (e.keyCode === 13) {
      CallRequest({ buscar: 1, paginate: 1 });
    }
  };

  return (
    <Fragment>
      <h1 className="page__title mt-5">Nómina general del Estado</h1>
      <div className="page__control">
        <script
          type="text/javascript"
          src="//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5b2a95135b7f0c9b"
        ></script>
        <div className="tools" style={{ height: 45 }}>
          <div className="d-flex align-items-center">
            <span>Nómina&nbsp;&nbsp;</span>
            <select
              value={periodo}
              style={{
                height: 35,
                border: "0.0625rem solid #e9e9e9",
                padding: 0,
                paddingLeft: 10,
              }}
              onChange={(e) => {
                setPeriodo(e.target.value);
              }}
            >
              {config?.periodos_nomina &&
                config?.periodos_nomina.map((obj, i) => {
                  return (
                    <option key={i} value={obj.codigo}>
                      {obj.descripcion}
                    </option>
                  );
                })}
            </select>
          </div>

          <div
            className="d-flex align-items-center justify-content-between col-4"
            style={{ fontSize: 20 }}
          >
            <button
              style={accessories.excelButtonStyle}
              onClick={onExportToExcel}
            >
              {excelStatus === "loading" ? "Cargando..." : "Exportar a XLS"}
            </button>

            <Tpdf
              referencia={tableNomina?.current}
              filename={
                Nomina
                  ? "Nomina " +
                    periodo +
                    ", Resultados del " +
                    Nomina.meta?.from +
                    " al " +
                    Nomina.meta?.to
                  : "Exportado a pdf"
              }
            />

            <span
              style={{ cursor: "pointer" }}
              onClick={() => setZoom(zoom + 1)}
            >
              <i className="fa fa-search-plus"></i>
            </span>
            <span
              style={{ cursor: "pointer" }}
              onClick={() => setZoom(zoom - 1)}
            >
              <i className="fa fa-search-minus"></i>
            </span>
          </div>
        </div>
      </div>
      <div className="table-responsive">
        <table
          className="table table-hover "
          style={{ marginTop: 25, fontSize: zoom }}
          ref={tableNomina}
        >
          <thead>
            <tr>
              <td>
                {" "}
                <input
                  className="form-control form-control-sm"
                  type="text"
                  name="nombres"
                  value={filtro.nombres || ""}
                  onKeyDown={handlerbuscar}
                  onChange={HandleFiltrar}
                  placeholder="Nombre"
                />
              </td>
              <td>
                {" "}
                <input
                  className="form-control form-control-sm"
                  type="text"
                  name="apellidos"
                  value={filtro.apellidos || ""}
                  onKeyDown={handlerbuscar}
                  onChange={HandleFiltrar}
                  placeholder="Apellido"
                />
              </td>
              <td>
                {" "}
                <input
                  className="form-control form-control-sm"
                  type="text"
                  name="institucion"
                  value={filtro.institucion || ""}
                  onKeyDown={handlerbuscar}
                  onChange={HandleFiltrar}
                  placeholder="Institución"
                />
              </td>
              <td>
                {" "}
                <input
                  className="form-control form-control-sm"
                  type="text"
                  name="cargo"
                  value={filtro.cargo || ""}
                  onKeyDown={handlerbuscar}
                  onChange={HandleFiltrar}
                  placeholder="Función"
                />
              </td>
              <td>
                {" "}
                <input
                  className="form-control form-control-sm"
                  type="text"
                  name="lugar"
                  value={filtro.lugar || ""}
                  onKeyDown={handlerbuscar}
                  onChange={HandleFiltrar}
                  placeholder="Área"
                />
              </td>
              <td>
                <select
                  className="form-control form-control-sm"
                  name="genero"
                  value={filtro.genero || ""}
                  onKeyDown={handlerbuscar}
                  onChange={HandleFiltrar}
                >
                  <option value="">Ambos</option>
                  <option value="MASCULINO">Masculino</option>
                  <option value="FEMENINO">Femenino</option>
                </select>
              </td>
              <td>
                <select
                  className="form-control form-control-sm"
                  name="estatus"
                  value={filtro.estatus || ""}
                  onKeyDown={handlerbuscar}
                  onChange={HandleFiltrar}
                >
                  <option value="">Todos</option>
                  {estatusEmpleados.map((d, i) => {
                    return (
                      <option value={d.TIPO_EMPLEADO} key={i}>
                        {d.TIPO_EMPLEADO}
                      </option>
                    );
                  })}
                </select>
              </td>
              <td
                style={{
                  display: "flex",
                  minWidth: 150,
                  justifyContent: "space-around",
                }}
              >
                <button
                  onClick={() => CallRequest({ buscar: 1, paginate: 1 })}
                  style={{
                    paddingTop: 5 + "px",
                    paddingRight: 10 + "px",
                    paddingLeft: 10 + "px",
                    background: "#003876",
                    color: "#fff",
                    border: "none",
                    borderRadius: 5 + "px",
                  }}
                >
                  <i className="fa fa-search"></i>
                </button>
                <button
                  onClick={() => handlerReset()}
                  style={{
                    paddingTop: 5 + "px",
                    paddingRight: 10 + "px",
                    paddingLeft: 10 + "px",
                    background: "#003876",
                    color: "#fff",
                    border: "none",
                    borderRadius: 5 + "px",
                  }}
                >
                  <i className="fa fa-redo"></i>
                </button>
              </td>
            </tr>
            <tr>
              <th scope="col">Nombres</th>
              <th scope="col">Apellidos</th>
              <th scope="col">Institución</th>
              <th scope="col">Función</th>
              <th scope="col">Área</th>
              <th scope="col">Género</th>
              <th scope="col">Estatus</th>
              <th scope="col">Sueldo bruto</th>
              <th scope="col"></th>
            </tr>
          </thead>

          <tbody>
            {loading && (
              <tr>
                <td colSpan="7">
                  <Loading />
                </td>
              </tr>
            )}
            {Nomina &&
              Nomina.data?.map((d, i) => {
                return (
                  <tr
                    key={i}
                    onDoubleClick={() => GetNomina(d.id, d.institucion)}
                  >
                    <td>{d.NOMBRES}</td>
                    <td>{d.APELLIDOS}</td>
                    <td>{d.INSTITUCION}</td>
                    <td>{d.CARGO}</td>
                    <td>{d.LUGAR_FUNCIONES}</td>
                    <td>{d.GENERO}</td>
                    <td>{d.TIPO_EMPLEADO}</td>
                    <td>{"RD$" + d.SUELDO_BRUTO_FIJOS}</td>
                    <td></td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        <div style={{ display: "flex" }}>
          <PaginationControlled
            eventChange={HandlerPagination}
            page={param.pag || 1}
            count={Nomina?.meta?.last_page}
          />
          <b>
            <p style={{ padding: 10 }}>
              Resultados del {Nomina?.meta?.from} al {Nomina?.meta?.to},Total:
              {Nomina?.meta?.total}
            </p>
          </b>
        </div>
      </div>
    </Fragment>
  );
};

const ContratosTable = ({ setLoading, loading, setRequestStatus }) => {
  const [filtro, setFiltro] = useState({});
  const [contrato, Setcontrato] = useState();
  const [periodo, setPeriodo] = useState();
  const [zoom, setZoom] = useState(12);
  const config = useConfigContext();
  const history = useHistory();
  const tableNomina = useRef(null);
  const param = useParams();

  const handlerbuscar = (e) => {
    if (e.keyCode === 13) {
      CallRequest({ buscar: 1, paginate: 1 });
    }
  };

  const CallRequest = (buscar) => {
    const obj = buscar || {};

    const form = new FormData();
    form.append("periodo", periodo);

    if (!obj.reset) {
      Object.entries(filtro).forEach((obj) => {
        form.append(obj[0], obj[1]);
      });
    }

    const _url = obj.reset
      ? `${url}compras?page=${obj.paginate || param.pag || 1}`
      : `${url}compras?page=${obj.paginate || param.pag || 1}&tramite_codigo=${
          filtro?.tramite_codigo || ""
        }&institucion_contrato=${
          filtro?.institucion_contrato || ""
        }&tipo_registro=${filtro?.tipo_registro || ""}&tipo_operacion=${
          filtro?.tipo_operacion || ""
        }&rnc=${filtro?.rnc || ""}&beneficiario=${
          filtro?.beneficiario || ""
        }&fecha_aprobacion=${filtro?.fecha_aprobacion || ""}`;

    setLoading(true);
    fetch(_url, {
      method: "get",
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        Setcontrato(res);
        obj.reset && history.push(`/consultas/contratos/1`);
        obj.buscar && history.push(`/consultas/contratos/1`);
        obj.paginate && history.push(`/consultas/contratos/${obj.paginate}`);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        setRequestStatus(true);
      });
  };

  useEffect(() => {
    CallRequest();
  }, []);

  useEffect(() => {
    setPeriodo(GetPeriodo());
  }, [config]);

  const GetPeriodo = () => {
    if (!config.periodos_nomina) return;
    return config.periodos_nomina[config.periodos_nomina.length - 1].code;
  };

  const HandleFiltrar = (event) => {
    setFiltro({ ...filtro, [event.target.name]: event.target.value });
  };

  const HandlerPagination = (i, value) => {
    CallRequest({ paginate: value });
  };

  const handlerReset = () => {
    setFiltro({});
    CallRequest({ reset: 1 });
  };

  return (
    <Fragment>
      <h1 className="page__title mt-5">
        Certificaciones de contratos emitidos por Contraloría
      </h1>
      <div className="page__control">
        <script
          type="text/javascript"
          src="//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5b2a95135b7f0c9b"
        ></script>
        <div className="tools row" style={{ height: 45 }}>
          <div className="d-flex align-items-center col-md-8">
            {/*<span>Contratos&nbsp;&nbsp;</span>
            <select
              value={periodo}
              style={{
                height: 35,
                border: "0.0625rem solid #e9e9e9",
                padding: 0,
                paddingLeft: 10,
              }}
              onChange={(e) => {
                setPeriodo(e.target.value);
              }}
            >
              {config.periodos_nomina &&
                config.periodos_nomina.map((obj, i) => {
                  return (
                    <option key={i} value={obj.code}>
                      {obj.description}
                    </option>
                  );
                })}
            </select>
         */}
          </div>
          <div
            className="d-flex align-items-center justify-content-between col-md-4"
            style={{ fontSize: 20 }}
          >
            <Toexel
              referencia={tableNomina.current}
              filename={
                contrato
                  ? "Certificaciones de contratos Resultados del " +
                    contrato.meta.from +
                    " al " +
                    contrato.meta.to
                  : "Exportado a xml"
              }
              buttonText={"Exportar a XLS"}
            />
            <Tpdf
              referencia={tableNomina.current}
              filename={
                contrato
                  ? "Certificaciones de contratos Resultados del " +
                    contrato.meta.from +
                    " al " +
                    contrato.meta.to
                  : "Exportado a pdf"
              }
            />
            <span
              style={{ cursor: "pointer" }}
              onKeyDown={handlerbuscar}
              onClick={() => setZoom(zoom + 1)}
            >
              <i className="fa fa-search-plus"></i>
            </span>
            <span
              style={{ cursor: "pointer" }}
              onKeyDown={handlerbuscar}
              onClick={() => setZoom(zoom - 1)}
            >
              <i className="fa fa-search-minus"></i>
            </span>
          </div>
        </div>
      </div>
      <div className="table-responsive">
        <table
          className="table table-hover"
          style={{ marginTop: 25, fontSize: zoom }}
          ref={tableNomina}
        >
          <thead>
            <tr>
              <td>
                {" "}
                <input
                  className="form-control form-control-sm"
                  onKeyDown={handlerbuscar}
                  type="text"
                  name="tramite_codigo"
                  value={filtro.tramite_codigo || ""}
                  onChange={HandleFiltrar}
                  placeholder="código"
                />
              </td>
              <td>
                {" "}
                <input
                  className="form-control form-control-sm"
                  onKeyDown={handlerbuscar}
                  type="text"
                  name="institucion_contrato"
                  value={filtro.institucion_contrato || ""}
                  onChange={HandleFiltrar}
                  placeholder="Contrato"
                />
              </td>
              <td>
                {" "}
                <input
                  className="form-control form-control-sm"
                  onKeyDown={handlerbuscar}
                  type="text"
                  name="tipo_registro"
                  value={filtro.tipo_registro || ""}
                  onChange={HandleFiltrar}
                  placeholder="Registro"
                />
              </td>
              <td>
                {" "}
                <input
                  className="form-control form-control-sm"
                  onKeyDown={handlerbuscar}
                  type="text"
                  name="tipo_operacion"
                  value={filtro.tipo_operacion || ""}
                  onChange={HandleFiltrar}
                  placeholder="Operación"
                />
              </td>
              <td></td>
              <td>
                {" "}
                <input
                  className="form-control form-control-sm"
                  onKeyDown={handlerbuscar}
                  type="text"
                  name="rnc"
                  value={filtro.rnc || ""}
                  onChange={HandleFiltrar}
                  placeholder="Documento"
                />
              </td>
              <td>
                {" "}
                <input
                  className="form-control form-control-sm"
                  onKeyDown={handlerbuscar}
                  type="text"
                  name="beneficiario"
                  value={filtro.beneficiario || ""}
                  onChange={HandleFiltrar}
                  placeholder="Beneficiario"
                />
              </td>
              <td>
                {/*<input className="form-control form-control-sm" type="date" name="fecha_inicio" value={filtro.fecha_inicio || ""} onChange={HandleFiltrar} placeholder="Fecha Inicio" />*/}
              </td>
              <td>
                {/*<input className="form-control form-control-sm" type="date" name="fecha_fin" value={filtro.fecha_fin || ""} onChange={HandleFiltrar} placeholder="Fecha fin" />*/}
              </td>
              <td>
                <input
                  className="form-control form-control-sm"
                  onKeyDown={handlerbuscar}
                  type="date"
                  name="fecha_aprobacion"
                  value={filtro.fecha_aprobacion || ""}
                  onChange={HandleFiltrar}
                  placeholder="Fecha de aprovación"
                />
              </td>
              <td
                style={{
                  display: "flex",
                  minWidth: 150,
                  justifyContent: "space-around",
                }}
              >
                <button
                  onClick={() => CallRequest({ buscar: 1, paginate: 1 })}
                  style={{
                    paddingTop: 5 + "px",
                    paddingRight: 10 + "px",
                    paddingLeft: 10 + "px",
                    background: "#003876",
                    color: "#fff",
                    border: "none",
                    borderRadius: 5 + "px",
                  }}
                >
                  <i className="fa fa-search"></i>
                </button>
                <button
                  onClick={() => handlerReset()}
                  style={{
                    paddingTop: 5 + "px",
                    paddingRight: 10 + "px",
                    paddingLeft: 10 + "px",
                    background: "#003876",
                    color: "#fff",
                    border: "none",
                    borderRadius: 5 + "px",
                  }}
                >
                  <i className="fa fa-redo"></i>
                </button>
              </td>
            </tr>

            <tr>
              <th scope="col">Tramite código</th>
              <th scope="col">Contrato institución</th>
              <th scope="col">Tipo de registro</th>
              <th scope="col">Tipo de operación</th>
              <th scope="col">Monto</th>
              <th scope="col">RNC/Documentos</th>
              <th scope="col">Beneficiario</th>
              <th scope="col">Fecha Inicio</th>
              <th scope="col">Fecha fin</th>
              <th scope="col">Fecha de aprobación</th>
              <th scope="col">Cuenta</th>
            </tr>
          </thead>
          <tbody>
            {loading && (
              <tr>
                <td colSpan="7">
                  <Loading />
                </td>
              </tr>
            )}
            {contrato &&
              contrato.data.map((d, i) => {
                return (
                  <tr key={i}>
                    <td>{d.tramite_codigo}</td>
                    <td>{d.institucion_contrato}</td>
                    <td>{d.tipo_registro}</td>
                    <td>{d.tipo_operacion}</td>
                    <td>{d.moneda + d.monto}</td>
                    <td>{d.rnc}</td>
                    <td>{d.beneficiario}</td>
                    <td>{d.fecha_inicio}</td>
                    <td>{d.fecha_fin}</td>
                    <td>{d.fecha_aprobacion}</td>
                    <td>{d.cuenta}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        <div style={{ display: "flex" }}>
          {/*<ReactPaginate
          className="pagination"
          pageCount={contrato && contrato.meta.last_page}
          onPageChange={HandlerPagination}
          breakClassName={"page-item"}
          breakLinkClassName={"page-link"}
          containerClassName={"pagination"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          previousClassName={"page-item"}
          previousLinkClassName={"page-link"}
          nextClassName={"page-item"}
          nextLinkClassName={"page-link"}
          activeClassName={"active"}
          previousLabel="<"
          nextLabel=">"
          disableInitialCallback={true}
          initialPage={param.pag ? param.pag - 1 : 0}
        />*/}
          <PaginationControlled
            eventChange={HandlerPagination}
            page={param.pag || 1}
            count={contrato && contrato.meta.last_page}
          />
          <b>
            <p style={{ padding: 10 }}>
              Resultados del {contrato && contrato.meta.from} al{" "}
              {contrato && contrato.meta.to},Total:
              {contrato && contrato.meta.total}
            </p>
          </b>
        </div>

        {useEffect(() => {
          return <h1>holta</h1>;
        }, [param])}
      </div>
    </Fragment>
  );
};

export default function Consulta() {
  const [loading, setLoading] = useState(false);
  const [RequestStatus, setRequestStatus] = useState(false);

  const param = useParams();

  useEffect(() => {
    if (!param.pag) window.scrollTo(0, 0);
  }, []);

  return (
    <Fragment>
      {param.search === "nomina" && (
        <div
          className="page-cover"
          style={{ backgroundImage: "url('/assets/img/nomina.jpg')" }}
        ></div>
      )}
      {param.search === "contratos" && (
        <div
          className="page-cover"
          style={{ backgroundImage: "url('/assets/img/contratos.jpg')" }}
        ></div>
      )}
      <section className="front">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              {param.search === "nomina" && (
                <NominaTable
                  loading={loading}
                  setLoading={setLoading}
                  setRequestStatus={setRequestStatus}
                />
              )}

              {param.search === "contratos" && (
                <ContratosTable
                  loading={loading}
                  setLoading={setLoading}
                  setRequestStatus={setRequestStatus}
                />
              )}
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}
