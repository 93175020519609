import { eliminarDiacriticos } from "./removeAcents";

export const FilterArray = (array = [], param, filterBy = "nombre") => {
  if (!param) return array;
  return array.filter((o) =>
    eliminarDiacriticos(o[filterBy].toLowerCase()).includes(
      eliminarDiacriticos(param.toLowerCase())
    )
  );
};

export const FilterArray2 = (arr = [], value = "", rawFilterBy = []) => {
  return arr.filter((item) => {
    let coincide = false;
    for (const key of rawFilterBy) {
      if (compare(item[key], value)) return (coincide = true);
    }

    return coincide;
  });
};

const compare = (propertyValue, value) => {
  return eliminarDiacriticos(propertyValue.toLowerCase()).includes(
    eliminarDiacriticos(value.toLowerCase())
  );
};
