import url from "../../../url";
import * as accessories from "../accessories";

import { useEffect, useState } from "react";
import { NominaTable } from "../accessories/table-nomina";
import { useParams } from "react-router";

export default function InstitucionNomina() {
  const params = useParams();

  const [state, setState] = useState({
    institution: {},
    payroll: [],
    pagination: {},
    payrollDate: [],
    loading: false,
    periodSelected: "",
    periodDefault: "",
    searchParams: {},
  });

  const onChange = (name, value) => {
    if (name === "periodSelected") {
      setState((curr) => ({
        ...curr,
        [name]: value,
      }));
    }

    setState((curr) => ({
      ...curr,
      searchParams: { ...curr.searchParams, [name]: value },
    }));
  };

  const onSearch = async () => {
    const { data } = await accessories.fetchRequest(
      accessories.buildQueryParams(
        accessories.buildQuery(false, { ...state }),
        accessories.buildAndValidateParams(state.searchParams).params
      )
    );
    setState((curr) => ({
      ...curr,
      payroll: data,
    }));
  };

  const onExport = async () => {
    const { params, isEmptyValue } = accessories.buildAndValidateParams(
      state.searchParams
    );

    if (isEmptyValue) return;

    const res = await accessories.fetchRequest(
      accessories.buildQueryParams(
        accessories.buildQuery("nominas/export", { ...state }),
        params
      )
    );

    accessories.exportTableNominaToExcel(res.data);
  };

  useEffect(() => {
    if (state.periodSelected) onSearch();
    //eslint-disable-next-line
  }, [state.periodSelected]);

  useEffect(() => {
    if (state.searchParams.page) onSearch();
    //eslint-disable-next-line
  }, [state.searchParams?.page]);

  useEffect(() => {
    const getInstitution = async () => {
      setState((curr) => ({ ...curr, loading: true }));

      const res1 = await fetch(
        `${url}instituciones/${params.institucionid}/show`,
        {
          method: "get",
          headers: new Headers({}),
        }
      );
      const institution = await res1.json();

      const res2 = await fetch(`${url}nominas/fechas`, {
        method: "get",
        headers: new Headers({}),
      });

      const payrollDate = await res2.json();
      const arrPayrollDate = payrollDate.data || [];
      const payrollCode = arrPayrollDate.length
        ? arrPayrollDate[arrPayrollDate.length - 1].codigo
        : "";

      const res3 = await fetch(
        `${url}nominas?paginar=30&periodo=${payrollCode}&organismo=${institution.data.organismo_id}`,
        {
          method: "get",
          headers: new Headers({}),
        }
      );

      const payroll = await res3.json();

      setState((curr) => ({
        ...curr,
        institution: institution.data,
        payroll: payroll.data,
        pagination: { links: payroll.links, meta: payroll.meta },
        payrollDate: payrollDate.data,
        periodDefault: payrollCode,
        loading: false,
      }));
    };

    getInstitution();
  }, [params.institucionid]);

  if (state.loading) return <div className="p-5"> Cargando nómina... </div>;
  return (
    <div>
      <NominaTable
        payroll={state.payroll}
        payrollDate={state.payrollDate}
        onChange={onChange}
        onSearch={onSearch}
        pagination={state.pagination}
        periodSelected={state.periodSelected || state.periodDefault}
        searchParams={state.searchParams}
        onExport={onExport}
      />
    </div>
  );
}
