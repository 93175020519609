import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import url from "../../url";
import { FilterArray, FilterArray2 } from "../../Utils/filterArray";

export default function useActions() {
  const [loading, setLoading] = useState(false);
  const [filtering, setfiltering] = useState();
  const [RequestStatus, setRequestStatus] = useState(false);
  const [instituciones, setInstituciones] = useState([]);
  const [FilteredInstitucions, setFilteredInstitucions] = useState([]);

  const { type } = useParams();

  const GetInstitucion = (url) => {
    setLoading(true);
    fetch(url, {
      method: "get",
      headers: new Headers({}),
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        setInstituciones(res.data);
        setLoading(false);
        setRequestStatus(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        setRequestStatus(true);
      });
  };

  const handlerFilter = (inputValue) => {
    setFilteredInstitucions(
      FilterArray2(instituciones, inputValue, ["nombre", "siglas"])
    );
    setfiltering(inputValue);
  };

  useEffect(() => {
    GetInstitucion(`${url}instituciones`);
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    type &&
      setFilteredInstitucions(
        FilterArray(instituciones, type, "poder_del_estado")
      );
    setfiltering(type);
  }, [type]);

  return {
    instituciones: filtering ? FilteredInstitucions : instituciones,
    loading,
    RequestStatus,
    type,
    handlerFilter,
    setFilteredInstitucions,
  };
}
