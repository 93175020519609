import React from "react";
import jsPDF from 'jspdf'
import 'jspdf-autotable'

const gettable=(referencia)=>{
  let tbref

  if(referencia) {
      tbref = referencia.cloneNode(true)
      tbref.deleteRow(0)
      return tbref
    }
  return ''
}

const print = (ref,filename) => {
  
  const doc = new jsPDF('landscape')

  const table=gettable(ref)

  let heder=[]
  let rows=[]

  for (var r = 0, n = table.rows.length; r < n; r++) {
    let row=[]
    for (var c = 0, m = table.rows[r].cells.length; c < m; c++) {

      if(r===0) {
        heder.push(table.rows[r].cells[c].innerHTML)
      }else{
        row.push(table.rows[r].cells[c].innerHTML)
      }
    }
    rows.push(row)   
  }
  // Or use javascript directly:
  doc.autoTable({
    head: [heder],
    body: rows,
  })
  doc.save(filename+'.pdf')
};

const Tpdf = ({referencia,filename}) => (
  <button title='Exportar consulta actual a PDF' style={{border:'none',background:'#DD190C',color:'#fff',fontSize:13,padding:8,borderRadius:5,fontWeight:600}} onClick={()=>print(referencia,filename)}>Exportar a PDF</button>
);

export default Tpdf

