import { Link, useHistory } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

export default function Home() {
  const history = useHistory();

  const handleRediret = (e) => {
    history.push(e);
  };

  return (
    <>
      <section className="slider">
        <OwlCarousel
          items={1}
          loop
          dots
          autoplay
          lazyLoad
          autoplayHoverPause
          autoplayTimeout={15000}
          className="owl-carousel owl-slider"
        >
          <div className="item">
            <img src="/assets/img/slider/02.jpg" alt={"null"} />
          </div>
          <div className="item">
            <img src="/assets/img/slider/03.jpg" alt={"null"} />
          </div>
          <div className="item">
            <img src="/assets/img/slider/04.jpg" alt={"null"} />
          </div>
          <div className="item">
            <img src="/assets/img/slider/01.jpg" alt={"null"} />
          </div>
        </OwlCarousel>
      </section>

      <section className="m-e">
        <div className="container">
          <div className="row no-gutters align-items-center">
            <div className="col-6">
              <Link to="/Inicio/marcolegaldetransparencia" className="link">
                <img src="/assets/img/marcolegal1.jpg" alt={"null"} />
              </Link>
            </div>
            <div className="col-6 ll-b">
              <Link to="/Inicio/transparenciaenlaestrategia" className="link">
                <img src="/assets/img/estrategia2030 (2).jpg" alt={"null"} />
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="info">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-6 mt-3" style={{ cursor: "pointer" }}>
              <div
                className="info__cap"
                onClick={() => handleRediret("/consultas/nomina")}
              >
                <div className="info__cap_data uno">
                  <div className="icon">
                    <img src="/assets/img/nomina.svg" alt={"null"} />
                  </div>

                  <span className="description">Nómina general del estado</span>
                </div>
              </div>
            </div>
            <div
              className="col-lg-3 col-6 mt-3"
              style={{ cursor: "pointer" }}
              onClick={() => handleRediret("/consultas/contratos")}
            >
              <div className="info__cap">
                <div className="info__cap_data dos">
                  <div className="icon">
                    <img src="/assets/img/contratos.svg" alt={"null"} />
                  </div>
                  <span className="description">
                    Certificaciones de contratos
                  </span>
                </div>
              </div>
            </div>
            <div
              className="col-lg-3 col-6 mt-3"
              style={{ cursor: "pointer" }}
              onClick={() => handleRediret("/instituciones")}
            >
              <div className="info__cap">
                <div className="info__cap_data tres">
                  <div className="icon">
                    <img src="/assets/img/instituciones.svg" alt={"null"} />
                  </div>
                  <span className="description">Instituciones del estado</span>
                </div>
              </div>
            </div>
            <div
              className="col-lg-3 col-6 mt-3"
              style={{ cursor: "pointer" }}
              onClick={() => handleRediret("/compras")}
            >
              <div className="info__cap">
                <div className="info__cap_data cuatro">
                  <div className="icon">
                    <img src="/assets/img/compras.svg" alt={"null"} />
                  </div>
                  <span className="description">Compras y contrataciones</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div
        className="page-resumen align-items-center"
        style={{ backgroundImage: "url('/assets/img/bannerbg.jpg')" }}
      >
        <div className="container">
          <div className="row align-items-center justify-content-center d-flex flex-column">
            <h3>Pagos a contratistas y proveedores del Estado</h3>
            {/* <p style={{ fontSize: 15 }}>
              Actualizado al Domingo 31 de enero del 2021
            </p> */}

            <a
              rel="noreferrer"
              href="https://www.tesoreria.gob.do/index.php/component/phocadownload/download/87fdbbab18e755767c326ded200e0a63588ef7b196e0b951afde3ce589d29f71"
              target="_blank"
            >
              ver pagos
            </a>
          </div>
        </div>
      </div>

      <section className="interest">
        <div className="container">
          <h3 className="interest__title">Más Transparencia</h3>
          <div className="row">
            <div className="col-lg-3 col-6">
              <a
                rel="noreferrer"
                href="//www.transparenciafiscal.gob.do/"
                target="_blank"
                className="link"
              >
                <img src="/assets/img/interes/i_01.png" alt="n" />
              </a>
            </div>
            <div className="col-lg-3 col-6">
              <a
                rel="noreferrer"
                href="//datos.gob.do/"
                target="_blank"
                className="link"
              >
                <img src="/assets/img/interes/i_02.png" alt="n" />
              </a>
            </div>
            <div className="col-lg-3 col-6">
              <a
                rel="noreferrer"
                href="//www.digepres.gob.do/"
                target="_blank"
                className="link"
              >
                <img src="/assets/img/interes/i_03.png" alt="n" />
              </a>
            </div>
            <div className="col-lg-3 col-6">
              <a
                rel="noreferrer"
                href="//gobiernoabierto.do/"
                target="_blank"
                className="link"
              >
                <img src="./assets/img/interes/i_04.png" alt="n" />
              </a>
            </div>
          </div>

          <div className="row justify-content-center ">
            <div className="col-6 col-lg-3">
              <a
                rel="noreferrer"
                href="https://map.gob.do"
                target="_blank"
                className="link"
              >
                <img width={250} src="./assets/img/Map.jpg" alt="n" />
              </a>
            </div>

            <div className="col-6 col-lg-3">
              <a
                rel="noreferrer"
                href="https://camaradecuentas.gob.do/index.php/areas-sustantivas/declaracion-jurada"
                target="_blank"
                className="link"
              >
                <img width={250} src="./assets/img/Camera.png" alt="n" />
              </a>
            </div>

            <div className="col-6 col-lg-3">
              <a
                rel="noreferrer"
                href="https://comunidad.comprasdominicana.gob.do/STS/DGCP/Login.aspx"
                target="_blank"
                className="link"
              >
                <img width={250} src="./assets/img/Shopping.svg" alt="n" />
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
