import { Fragment } from "react";
import { Link } from "react-router-dom";

export default function Tramsparencia() {
  window.scrollTo(0, 0);
  return (
    <Fragment>
      <div
        className="page-cover"
        style={{
          backgroundImage: "url('/assets/img/pages/estrategia2030.jpg')",
        }}
      ></div>
      <main role="main" className="container">
        <div className="row">
          <div className="col-lg-9">
            <section className="page">
              <div className="page__breadcrumb1s">
                <span className="page__breadcrumb1s_here">
                  Estás aquí:&nbsp;
                </span>
                <span className="page__breadcrumb1s_home">
                  <Link to="/Index">Inicio</Link>&nbsp;/&nbsp;
                </span>
                <span>
                  Marco legal de transparencia en República Dominicana
                </span>
              </div>

              <h1 className="page__title">
                Marco legal de transparencia en República Dominicana
              </h1>

              <div className="page__control">
                <script
                  type="text/javascript"
                  src="//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5b2a95135b7f0c9b"
                ></script>
                <div className="tools">
                  <div className="addthis_inline_share_toolbox"></div>
                </div>
              </div>

              <article>
                En este portal interactivo hacemos disponible todo lo requerido
                en la Ley General de Libre Acceso a la Información Pública Ley
                200-04 y el Decreto No. 130-05 que crea el reglamento de la ley.
                <br />
                <br />
                Artículo 5.- «Se dispone la informatización y la incorporación
                al sistema de comunicación por Internet, o a cualquier otro
                sistema similar que en el futuro se establezca, de todos los
                organismos públicos centralizados y descentralizados del Estado,
                incluyendo el Distrito Nacional y los Municipios, con la
                finalidad de garantizar a través de éste un acceso directo del
                público a la información del Estado.»
                <br />
                <br />
                Todos los poderes y organismos del Estado deberán instrumentar
                la publicación de sus respectivas «Páginas Web» a los siguientes
                fines:
                <br />
                <br />
                • Difusión de Información: <br />
                Estructura, integrantes, normativas de funcionamiento,
                proyectos, informes de gestión, base de datos;
                <br />
                <br />
                • Centro de Intercambio y Atención al Cliente o Usuario: <br />
                Consultas, quejas y sugerencias;
                <br />
                <br />
                • Trámites o Transacciones Bilaterales: <br />
                La información a que hace referencia el párrafo anterior será de
                libre acceso al público sin necesidad de petición previa.
                <br />
                <br />
              </article>
            </section>
          </div>
        </div>
      </main>
    </Fragment>
  );
}
