import { Link, useHistory } from "react-router-dom";
export default function Statistics() {
  return (
    <>
      <main role="main" className="container">
        <div className="center-block">
          <div
            className="col-md-12"
            style={{
              display: "flex",
              justifyContent: "center",
              padding: 150 + "px",
            }}
          >
            <div className="error-template">
              <h1>Oops!</h1>
              <h2>404 Not Found</h2>
              <div className="error-details">
                Sorry, an error has occured, Requested page not found!
              </div>
              <div className="error-actions">
                <Link to="/" className="btn btn-primary btn-lg">
                  <span className="glyphicon glyphicon-home"></span>
                  Take Me Home{" "}
                </Link>
                <a href="/" className="btn btn-default btn-lg">
                  <span className="glyphicon glyphicon-envelope"></span> Contact
                  Support{" "}
                </a>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
