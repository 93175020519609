import PageIns from "../../components/PageIns";
import AsideBar from "../../components/AsidemenuInstitutios";
import { Link } from "react-router-dom";
import Loading from "../../components/Loading";
import Notification from "../../components/Notificacion";
import useActions from "./useActions";
export default function Institucions() {
  const { instituciones, type, loading, handlerFilter } = useActions();

  return (
    <>
      <div
        className="page-cover"
        style={{ backgroundImage: "url('/assets/img/instituciones.jpg')" }}
      ></div>

      <main role="main" className="container">
        <div className="row">
          <div className="col-lg-3">
            <AsideBar active={type} />
          </div>
          <div className="col-lg-9">
            <section className="page">
              <div className="page__breadcrumb1s">
                <span className="page__breadcrumb1s_here">
                  Estás aquí:&nbsp;
                </span>
                <span className="page__breadcrumb1s_home">
                  <Link to="/Index">Inicio</Link>&nbsp;/&nbsp;
                </span>
                <span className="page__breadcrumb1s_home">
                  <Link to="/instituciones">Instituciones</Link>&nbsp;/&nbsp;
                </span>
                <span>{type}</span>
              </div>
              <h1 className="page__title">{type}</h1>

              <div className="page__control">
                <script
                  type="text/javascript"
                  src="//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5b2a95135b7f0c9b"
                ></script>
                <div className="tools">
                  <div className="addthis_inline_share_toolbox front__search_input input-group">
                    <input
                      type="text"
                      className="search-input form-control"
                      name="filter"
                      placeholder="Ingresar búsqueda"
                      onChange={({ target }) => handlerFilter(target.value)}
                    />
                  </div>
                </div>
              </div>

              <article>
                {loading ? <Loading /> : ""}

                {false ? (
                  <Notification
                    title="Upps, Ocurrio un error en su solicitud"
                    body="Por favor intentelo nuevamente"
                  />
                ) : (
                  ""
                )}
                <div className="row">
                  {instituciones.map((obj, index) => {
                    return (
                      <PageIns
                        key={index}
                        title={obj.nombre}
                        path={`/institucion/${obj.id}`}
                      />
                    );
                  })}
                </div>
              </article>
            </section>
          </div>
        </div>
      </main>
    </>
  );
}
